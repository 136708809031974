import { checkSusscessRequest } from 'services/api';
import nftServices from 'services/nft';

export const useListForSaleNFT = () => {
  const handleListForSaleNFT = async (
    id: string,
    data: any,
    { onSuccess, onError }: { onSuccess: () => void; onError: () => void },
  ) => {
    try {
      const response = await nftServices.handleListForSaleNFT(id, data);
      if (checkSusscessRequest(response)) {
        onSuccess && onSuccess();
      } else {
        onError && onError();
      }
    } catch (error) {
    } finally {
    }
  };

  return {
    onListForSaleNFT: handleListForSaleNFT,
  };
};
