import React from 'react';
import selectedConfig from 'redux/config/selector';

import { TOKEN_SUPPORT } from 'constants/common';
import { useAppSelector } from './useStore';

export function useGetConfig() {
  const { general = {} } = useAppSelector(selectedConfig.getConfig);
  const { currencies = [], mintingQuantityMax = 0, attributes = [], ipfsGateway } = general;

  const selectedCurrency = currencies?.find((currency: any) => currency?.name === TOKEN_SUPPORT.value);
  return { currency: { ...selectedCurrency, icon: TOKEN_SUPPORT.icon }, mintingQuantityMax, attributes, ipfsGateway };
}
