import TokenLogo from 'resources/svg/ekoios_token.svg';

export const SECOND_NUMBER = 60;

export const PERCENTAGE_NUMBER = 100;

export const FILE_BINARY_SIZE = 1024;

export const IMAGE_MEDIUM_SIZE = {
  WIDTH: 300,
  HEIGHT: 300,
};

export const IMAGE_SMALL_SIZE = {
  WIDTH: 50,
  HEIGHT: 50,
};

export const IMAGE_TYPE = 'image/png';

export const UPLOAD_STATUS = {
  DONE: 'done',
  ERROR: 'error',
  OK: 'ok',
};

export const TOKEN_SUPPORT = {
  symbol: 'AOG',
  icon: TokenLogo,
  value: 'ageofgods',
};

export const SOCKET_EVENT = {
  NOTIFICATION: 'notification',
  REMOVE_FROM_SALE: 'removeFromSale',
  ADMIN_MINT: 'adminMint',
};

export const ZERO_VALUE = 0;
export const DOLLAR_TEXT = '$';
export const PAD_START_TEXT = '0';
export const PAD_START_MAX_LENGTH = 4;
export const MAX_NFT_CODE_LENGTH = 10;
export const EMPTY_DEFAULT_TEXT = '--';
export const FORMAT_TIME_PICKER = 'HH:mm:ss';
export const FORMAT_DATE_PICKER = 'DD-MM-YYYY';
export const DEFAULT_SEARCH_DATE_FORMAT = 'DD-MM-YYYY';

export const EVENT_NOTIFICATION = {
  ADMIN_PUT_ON_SALE: 'admin-put-on-sale',
  BUY_FROM_ADMIN: 'buy-from-admin',
  BUY_FROM_USER: 'buy-from-user',
  DEACTIVE_SELLORDER: 'deactive-sellorder',
  ACTIVE_SELLORDER: 'active-sellorder',
  DEACTIVE_SELLORDER_ADMIN: 'deactive-sellorder-admin',
};
