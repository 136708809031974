import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { isString } from 'lodash';

import Card from 'components/AppCard';
import FormItem, { TYPE_INPUT } from 'components/FormItem';

import selectedConfig from 'redux/config/selector';

import { useAppSelector } from 'hooks/useStore';
import { limitMaxlengNumber, limitPercentage } from 'utils';
import LENGTH_CONSTANTS from 'constants/length';
import {
  ATTRIBUTE_EXCLUDE,
  LOCALIZATION_NFT_CREATION_ATTRIBUTE,
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_TOTAL_SUPPLY,
  NFT_DECIMAL_SCALE,
  NFT_DEFAULT_CREATE_FIELD,
  NFT_POSITIVE_SCALE,
} from 'constants/nft';

const NFTAtrribute = () => {
  const { t } = useTranslation();
  const { general = {} } = useAppSelector(selectedConfig.getConfig);

  const { attributes = {} } = general;

  const listAttribute = Object.values(attributes);
  const attributeWithoutReduceAttribute = listAttribute.filter(
    (attribute: any) => !ATTRIBUTE_EXCLUDE.includes(attribute?.name),
  );
  const excludeAttribute = listAttribute.filter((attribute: any) => ATTRIBUTE_EXCLUDE.includes(attribute?.name));

  const renderAttributeFormItem = (attributes: Array<any>, options?: any) => {
    return attributes?.map((attribute: any) => {
      const label = t(LOCALIZATION_NFT_CREATION_ATTRIBUTE?.[attribute?.name]?.text);
      const placeholder = t(LOCALIZATION_NFT_CREATION_ATTRIBUTE?.[attribute?.name]?.placeholder);
      const typeInput = attribute?.type?.toUpperCase();
      const selectOptions =
        typeInput === TYPE_INPUT.SELECT
          ? (attribute?.value || [])?.map((item: any) => {
              const value = isString(item) ? item : item?.text;
              return {
                label: value,
                value,
              };
            })
          : [];
      return (
        <Col key={attribute?.name} {...options}>
          <FormItem
            name={attribute?.name}
            label={label}
            required={attribute?.required}
            placeholder={placeholder}
            typeInput={typeInput}
            options={selectOptions}
            thousandSeparator
            maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT}
          />
        </Col>
      );
    });
  };

  return (
    <Card className="nft-creation-page-attribute">
      <Row gutter={20}>
        {renderAttributeFormItem(excludeAttribute, { md: 24, xs: 24 })}

        <Col md={24} xs={24}>
          <FormItem
            name={NFT_DEFAULT_CREATE_FIELD.NAME}
            label={t('nft_creation.txt_name')}
            required
            placeholder={t('nft_creation.txt_name_placeholder')}
          />
        </Col>
        <Col md={12} xs={24}>
          <FormItem
            name={NFT_DEFAULT_CREATE_FIELD.ROYALTYFEE}
            label={t('nft_creation.txt_royalties')}
            required
            placeholder={t('nft_creation.txt_royalties_placeholder')}
            typeInput={TYPE_INPUT.NUMBER}
            thousandSeparator
            decimalScale={NFT_DECIMAL_SCALE}
            isAllowed={limitPercentage}
            appendInput={<span className="field__span">%</span>}
          />
        </Col>
        <Col md={12} xs={24}>
          <FormItem
            name={NFT_DEFAULT_CREATE_FIELD.TOTAL_SUPPLY}
            label={t('nft_creation.txt_total_supply')}
            required
            placeholder={t('nft_creation.txt_total_supply_placeholder')}
            typeInput={TYPE_INPUT.NUMBER}
            thousandSeparator
            decimalScale={NFT_POSITIVE_SCALE}
            isAllowed={limitMaxlengNumber(MAX_LENGTH_TOTAL_SUPPLY)}
          />
        </Col>
        <Col md={24} xs={24}>
          <FormItem
            name={NFT_DEFAULT_CREATE_FIELD.DESCRIPTION}
            label={t('nft_creation.txt_description')}
            placeholder={t('nft_creation.txt_description_placeholder')}
            typeInput={TYPE_INPUT.TEXTAREA}
            description={t('nft_creation.txt_sub_description')}
            maxLength={MAX_LENGTH_DESCRIPTION}
            showCount
          />
        </Col>

        {renderAttributeFormItem(attributeWithoutReduceAttribute, { md: 12, xs: 24 })}
      </Row>
    </Card>
  );
};

export default NFTAtrribute;
