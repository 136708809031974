import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchForm from '../SearchForm';
import Sale from '../Sale';
import List from '../List';
import PageContent from 'components/PageContent';
import { useGetRevenue } from 'pages/revenue-management/hooks';

import AppIcon from 'resources/svg/app_icon.svg';

import { formatCurrency, getNumberValue } from 'utils';
import LENGTH_CONSTANTS from 'constants/length';
import { COLUMN, CURRENCY, REVENUE_FIELD } from 'constants/revenue';

const { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } = LENGTH_CONSTANTS;
const { PAGE, LIMIT, RESELL } = REVENUE_FIELD;
const {
  NO,
  SELLER,
  SALE_DATE,
  BUYER,
  QUANTITY,
  NFT_ID,
  PRICE,
  NFT_NAME,
  SUBTOTAL,
  ROYALTIES,
  TOKEN_ID,
  ACTIONS,
  TYPE: TYPE_COLUMN,
} = COLUMN;

const defaultValue = [NO, SALE_DATE, QUANTITY, PRICE, NFT_NAME, SUBTOTAL, ROYALTIES, TOKEN_ID, ACTIONS];

const initialValues = {
  keyword: '',
  saleOrderType: RESELL,
  from: null,
  until: null,
  page: DEFAULT_PAGE,
  limit: DEFAULT_PAGE_SIZE,
};

const Secondary = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initialValues);
  const { data, total, loading, dataSale } = useGetRevenue(params) as any;
  const [secondaryColumn, setSecondaryColumn] = useState(defaultValue);

  const secondarySaleItem = [
    {
      label: 'revenue_management.txt_secondary_sales_revenue',
      value: formatCurrency(getNumberValue(dataSale?.revenue?.secondarySale), { isNotCompare: true }),
      toolTip: t('revenue_management.txt_secondary_revenue_tooltip'),
      appIcon: AppIcon,
      currency: CURRENCY,
      usd: getNumberValue(dataSale?.revenue?.secondarySaleUsd),
    },
    {
      label: 'revenue_management.txt_secondary_sales_volume',
      value: formatCurrency(getNumberValue(dataSale?.volume?.secondarySale), { isNotCompare: true }),
      toolTip: t('revenue_management.txt_secondary_volume_tooltip'),
      appIcon: AppIcon,
      currency: CURRENCY,
      usd: getNumberValue(dataSale?.volume?.secondarySaleUsd),
    },
    {
      label: 'revenue_management.txt_secondary_transactions',
      value: total,
    },
    {
      label: 'revenue_management.txt_secondary_nfts_sold',
      value: getNumberValue(dataSale?.nftSold),
    },
  ];

  const secondaryColumnsItem = [
    { label: t('revenue_management.txt_no'), value: NO, disabled: true },
    { label: t('revenue_management.txt_buyer'), value: BUYER },
    { label: t('revenue_management.txt_sale_date'), value: SALE_DATE },
    { label: t('revenue_management.txt_quantity'), value: QUANTITY },
    { label: t('revenue_management.txt_type'), value: TYPE_COLUMN },
    { label: t('revenue_management.txt_price'), value: PRICE },
    { label: t('revenue_management.txt_nft_id'), value: NFT_ID },
    { label: t('revenue_management.txt_subtotal'), value: SUBTOTAL },
    { label: t('revenue_management.txt_nft_name'), value: NFT_NAME },
    { label: t('revenue_management.txt_royalties'), value: ROYALTIES },
    { label: t('revenue_management.txt_token_id'), value: TOKEN_ID },
    { label: t('revenue_management.txt_actions'), value: ACTIONS, disabled: true },
    { label: t('revenue_management.txt_seller'), value: SELLER },
  ];

  const getListColumn = (listColumn: any) => setSecondaryColumn(listColumn);

  const handleChangePaging = (page: number, limit: number) => {
    setParams({
      ...params,
      [PAGE]: limit !== params?.limit ? DEFAULT_PAGE : page,
      [LIMIT]: limit,
    });
  };

  return (
    <PageContent>
      <SearchForm
        onSetParams={setParams}
        params={params}
        getListColumn={getListColumn}
        listColumn={secondaryColumn}
        initialValues={initialValues}
        columnsItem={secondaryColumnsItem}
      />
      <Sale saleItem={secondarySaleItem} />
      <List
        total={total}
        listSale={data}
        loading={loading}
        page={params?.page}
        limit={params?.limit}
        params={params}
        listColumn={secondaryColumn}
        handleChangePaging={handleChangePaging}
        onSetParams={setParams}
      />
    </PageContent>
  );
};

export default Secondary;
