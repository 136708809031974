import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import Card from 'components/AppCard';
import PreviewNFT from 'components/PreviewNFT';

import NFTPreviewPlaceholder from 'resources/images/nft_preview_placeholder.png';

import { getFormatedNFT } from 'utils';
import { NFT_ATTRIBUTE_CREATED_FIELD, NFT_DEFAULT_CREATE_FIELD, NFT_MEDIA } from 'constants/nft';

const { FILE, FILE_PREVIEW, NAME, TOTAL_SUPPLY } = NFT_DEFAULT_CREATE_FIELD;
const { CLASS, GOD, LEVEL, MYTHOLOGY } = NFT_ATTRIBUTE_CREATED_FIELD;
const { IMAGE } = NFT_MEDIA;

const NFTPreview = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext() as any;

  const nftFormat = getFormatedNFT(values?.[FILE]);
  const srcContent = values?.[FILE]?.previewContent;
  const srcPreview = values?.[FILE_PREVIEW]?.previewContent;

  const isPreviewNFT =
    srcPreview ||
    srcContent ||
    values?.[NAME] ||
    values?.[TOTAL_SUPPLY] ||
    values?.[CLASS] ||
    values?.[GOD] ||
    values?.[LEVEL] ||
    values?.[MYTHOLOGY];

  const renderPreviewSrc = useMemo(() => {
    if (srcContent && nftFormat === IMAGE) {
      return srcContent;
    } else if (srcPreview && nftFormat !== IMAGE) {
      return srcPreview;
    }
    return null;
  }, [srcContent, srcPreview, nftFormat]);

  return (
    <Card className="nft-creation-page-preview">
      <p className="nft-creation-page-preview__header">{t('nft_creation.txt_preview')}</p>
      {!isPreviewNFT ? (
        <div className="preview-image preview-placeholder">
          <img src={NFTPreviewPlaceholder} />
          <span className="preview-placeholder__description">{t('nft_creation.txt_preview_placeholder')}</span>
        </div>
      ) : (
        <PreviewNFT nft={values} previewSrc={renderPreviewSrc} isPreview={false} />
      )}
    </Card>
  );
};

export default NFTPreview;
