import React from 'react';
import { Link } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import classNames from 'classnames';

import NumberFormat from 'components/NumberFormat';
import EllipsisText from 'components/EllipsisText';
import ResponsiveImage from 'components/ResponsiveImage';

import { formatDate, formatPadStart } from 'utils';
import { renderRoutes } from 'constants/routes';
import { NFT_STATUS } from 'constants/nft';

export const columns = (t: TFunction, page: number, limit: number) => [
  {
    title: t('nft_management.txt_no'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 50,
    render: (_value: any, _row: any, index: number) => (page - 1) * limit + index + 1,
  },
  {
    title: t('nft_management.txt_created_date'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
    sorter: true,
    render: (value: any) => formatDate(value),
  },
  {
    title: t('nft_management.txt_nft_id'),
    dataIndex: 'code',
    key: 'code',
    width: 75,
    sorter: true,
    render: (value: any) => formatPadStart(value),
  },
  {
    title: t('nft_management.txt_nft_name'),
    dataIndex: 'name',
    key: 'name',
    width: 125,
    sorter: true,
    ellipsis: true,
    render: (_value: string, row: any) => {
      return (
        <div className="view-nft-name">
          <ResponsiveImage src={row?.image?.smallUrl} />
          <EllipsisText text={row?.name} />
        </div>
      );
    },
  },
  {
    title: t('nft_management.txt_total_supply'),
    dataIndex: 'totalSupply',
    key: 'totalSupply',
    width: 100,
    sorter: true,
    render: (value: any) => {
      return <NumberFormat thousandSeparator value={value} displayType="text" />;
    },
  },
  {
    title: t('nft_management.txt_total_minted'),
    dataIndex: 'totalMinted',
    key: 'totalMinted',
    width: 100,
    sorter: true,
    render: (value: any) => {
      return <NumberFormat thousandSeparator value={value} displayType="text" />;
    },
  },
  {
    title: t('nft_management.txt_onsale_quantity'),
    dataIndex: 'onSaleQuantity',
    key: 'onSaleQuantity',
    width: 100,
    sorter: true,
    render: (value: any) => {
      return <NumberFormat thousandSeparator value={value} displayType="text" />;
    },
  },
  {
    title: t('nft_management.txt_status'),
    dataIndex: 'status',
    key: 'status',
    width: 75,
    render: (value: string) => {
      const curStatus = NFT_STATUS.find((status) => status?.value === value) as any;
      return (
        <div className={classNames('view-nft-status', `view-nft-status-${curStatus?.value}`)}>{t(curStatus?.name)}</div>
      );
    },
  },
  {
    title: t('nft_management.txt_action'),
    dataIndex: '_id',
    key: '_id',
    width: 75,
    render: (value: any) => {
      return (
        <Link className="view-nft-detail" to={renderRoutes.NFT_DETAIL(value)}>
          {t('common.txt_view_detail')}
        </Link>
      );
    },
  },
];
