import React, { Fragment } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import EllipsisText from 'components/EllipsisText';
import NumberFormat from 'components/NumberFormat';
import ItemWithLabel from 'components/ItemWithLabel';

import { REVENUE_DECIMAL_SCALE } from 'constants/revenue';
import { DOLLAR_TEXT } from 'constants/common';
import AppNumber from 'components/AppNumber';
import { NFT_DECIMAL_SCALE } from 'constants/nft';

type SaleProps = {
  saleItem: Array<any>;
};

type SaleItemType = {
  label: string;
  value: any;
  toolTip?: string;
  appIcon?: string;
  usd?: number | string;
  currency?: string;
};

const Sale = ({ saleItem }: SaleProps) => {
  const { t } = useTranslation();

  const renderSaleItem = ({ label, value, toolTip, appIcon, currency, usd }: SaleItemType) => {
    return (
      <Fragment>
        <div className="label">
          <ItemWithLabel helpText={toolTip} label={t(label)}></ItemWithLabel>
        </div>
        <div className="info">
          {appIcon ? (
            <Fragment>
              <img src={appIcon} className="info__app-icon" />
              <NumberFormat thousandSeparator displayType="text" value={value} decimalScale={REVENUE_DECIMAL_SCALE} />
              <EllipsisText text={currency} className="info__currency" />
            </Fragment>
          ) : (
            <NumberFormat
              thousandSeparator
              displayType="text"
              value={value}
              className="info__value"
              decimalScale={REVENUE_DECIMAL_SCALE}
            />
          )}
        </div>
        {usd && (
          <div className="usd">
            ({DOLLAR_TEXT}&nbsp;
            <AppNumber value={usd} decimalScale={NFT_DECIMAL_SCALE} />)
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <Row justify="space-between" className="revenue-management-sale">
      {saleItem?.map((item, index) => (
        <Col key={index} md={6} className="col-item">
          {renderSaleItem(item)}
        </Col>
      ))}
    </Row>
  );
};

export default Sale;
