import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import EllipsisText from 'components/EllipsisText';
import InfoItem from '../InfoItem';
import { NftDetailContext } from 'pages/nft-detail';

import { getImageAttribute, getValueAttribute } from 'utils';
import { LOCALIZATION_NFT_DETAIL_ATTRIBUTE } from 'constants/nft';

const Attribute = () => {
  const { t } = useTranslation();

  const { nftDetail = {} } = useContext(NftDetailContext) as any;
  const { attributes = {} } = nftDetail;

  const renderAttributeItem = Object.keys(LOCALIZATION_NFT_DETAIL_ATTRIBUTE).map((attribute: any, index: number) => {
    const valueAttribute = getValueAttribute(attributes, attribute);
    const imageAttribute = getImageAttribute(attributes, attribute);
    const label = t(LOCALIZATION_NFT_DETAIL_ATTRIBUTE?.[attribute]?.text);

    return (
      <InfoItem label={label} key={index}>
        <div className="attribute">
          {imageAttribute ? <img src={imageAttribute} alt="attribute nft" /> : <div />}
          <EllipsisText text={valueAttribute} className="attribute__text" />
        </div>
      </InfoItem>
    );
  });

  return <Row gutter={20}>{renderAttributeItem}</Row>;
};

export default Attribute;
