import { number, object, string, ref, date } from 'yup';
import { TFunction } from 'react-i18next';

import {
  MAX_LENGTH_DESCRIPTION,
  MAX_VALUE_ROYALTY_FEE,
  MAX_VALUE_TOTAL_COPIES,
  MIN_VALUE_TOTAL_COPIES,
  NFT_ATTRIBUTE_CREATED_FIELD,
  NFT_DEFAULT_CREATE_FIELD,
  NFT_MEDIA,
  NFT_MINTED_FIELD,
} from 'constants/nft';
import { isAddress } from 'utils';

const { NAME, ROYALTYFEE, IS_PUT_ON_SALE, TOTAL_SUPPLY, QUANTITY, UNIT_PRICE, DESCRIPTION, FILE, FILE_PREVIEW } =
  NFT_DEFAULT_CREATE_FIELD;
const { CLASS, GOD, LEVEL, MYTHOLOGY, TYPE } = NFT_ATTRIBUTE_CREATED_FIELD;
const { TO_ADDRESS } = NFT_MINTED_FIELD;

export const getNftSchema = (t: TFunction) => {
  return object({
    [FILE]: object().shape({
      previewContent: string().required(t('message.E5', { field: t('nft_creation.txt_nft_content') })),
    }),
    [FILE_PREVIEW]: object().when(FILE, {
      is: (val: any) => {
        return val?.fileList?.[0]?.type?.split('/')?.[0] !== NFT_MEDIA.IMAGE;
      },
      then: object().shape({
        previewContent: string().required(t('message.E5', { field: t('nft_creation.txt_nft_preview') })),
      }),
    }),
    [NAME]: string()
      .trim()
      .required(t('message.E5', { field: t('nft_creation.txt_name') })),
    [TYPE]: string()
      .nullable()
      .required(t('message.E5', { field: t('nft_creation.txt_type') })),
    [TOTAL_SUPPLY]: number()
      .required(t('message.E5', { field: t('nft_creation.txt_total_supply') }))
      .min(MIN_VALUE_TOTAL_COPIES, t('message.E6'))
      .max(MAX_VALUE_TOTAL_COPIES, t('message.E4')),
    [ROYALTYFEE]: number()
      .required(t('message.E5', { field: t('nft_creation.txt_royalties') }))
      .positive(t('message.E6'))
      .max(MAX_VALUE_ROYALTY_FEE, t('message.E3', { number: MAX_VALUE_ROYALTY_FEE })),
    [DESCRIPTION]: string().max(MAX_LENGTH_DESCRIPTION, t('message.E13')),
    [MYTHOLOGY]: string()
      .nullable()
      .required(t('message.E5', { field: t('nft_creation.txt_mythology') })),
    [GOD]: string()
      .trim()
      .required(t('message.E5', { field: t('nft_creation.txt_god') })),
    [LEVEL]: string()
      .nullable()
      .required(t('message.E5', { field: t('nft_creation.txt_level') })),
    [CLASS]: string()
      .nullable()
      .required(t('message.E5', { field: t('nft_creation.txt_class') })),
    [QUANTITY]: number().when(IS_PUT_ON_SALE, {
      is: (value: boolean) => !!value,
      then: number()
        .positive(t('message.E6'))
        .required(t('message.E5', { field: t('nft_creation.txt_onsale_quantity') }))
        .min(MIN_VALUE_TOTAL_COPIES, t('message.E6'))
        .max(MAX_VALUE_TOTAL_COPIES, t('message.E4'))
        .when(TOTAL_SUPPLY, {
          is: (value: any) => !!value,
          then: number().max(ref(TOTAL_SUPPLY), (value) => t('message.E8', { number: value?.max })),
        }),
    }),
    [UNIT_PRICE]: number().when(`${IS_PUT_ON_SALE}`, {
      is: (value: boolean) => !!value,
      then: number()
        .positive(t('message.E6'))
        .required(t('message.E5', { field: t('nft_creation.txt_listing_price') })),
    }),
  });
};

export const getListForSaleSchema = (t: TFunction, maxQuantity: number) =>
  object().shape({
    [QUANTITY]: number()
      .positive(t('message.E6'))
      .required(t('message.E5', { field: t('nft_list_for_sale.txt_on_sale_quantity') }))
      .max(
        maxQuantity,
        t('message.E8', {
          number: maxQuantity,
        }),
      ),
    [UNIT_PRICE]: number()
      .positive(t('message.E6'))
      .required(t('message.E5', { field: t('nft_list_for_sale.txt_listing_price') })),
  });

export const getMintSchema = (t: TFunction, maxQuantity: number) =>
  object().shape({
    [QUANTITY]: number()
      .positive(t('message.E6'))
      .required(t('message.E5', { field: t('nft_detail.txt_mint_quantity') }))
      .max(
        maxQuantity,
        t('message.E8', {
          number: maxQuantity,
        }),
      ),
    [TO_ADDRESS]: string()
      .trim()
      .required(t('message.E5', { field: t('nft_detail.txt_recipient_wallet_address') }))
      .test('isAddress', t('message.E16'), (value: any) => {
        return isAddress(value);
      }),
  });

export const getExportModalSchema = (t: TFunction) => {
  return object({
    from: date()
      .nullable()
      .required(t('message.E5', { field: 'Start Date' })),
    until: date()
      .nullable()
      .required(t('message.E5', { field: 'End Date' })),
  });
};
