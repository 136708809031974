import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { AddressZero } from '@ethersproject/constants';
import { Contract } from '@ethersproject/contracts';
import { getAddress } from '@ethersproject/address';
import { ethers } from 'ethers';

import ExchangeABI from 'constants/abi/exchange.json';
import { PROXY_ADDRESS } from 'connectors/constants';
import WALLET_STATUS from 'constants/walletStatus';
import { NFT_TRANSACTION_STATUS } from 'constants/nft';

export function isAddress(address: string) {
  try {
    return getAddress(address);
  } catch {
    return false;
  }
}

export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library?.getSigner(account)?.connectUnchecked();
}

// account is optional
function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || isNativeToken(address)) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

export function isNativeToken(address: string) {
  return address === AddressZero;
}

export default class BaseWalletService {
  address: string | null;
  needTobeInitiated: any;
  initUnit256: any;

  constructor(props: any) {
    this.address = props?.address;
  }

  isAdmin = async (library: any, account: string) => {
    try {
      const contract = getContract(PROXY_ADDRESS, ExchangeABI.output.abi, library, account);
      const response = await contract.isAdmin(account);
      return response;
    } catch (error) {
      return false;
    }
  };

  verifyLoginSignature = async ({
    library,
    creator,
    cancelMetamask,
  }: {
    library: any;
    creator: string;
    cancelMetamask: () => void;
  }) => {
    let signVerify: any = null;
    let hasnVerify = null;

    try {
      hasnVerify = ethers.utils.solidityKeccak256(['address'], [creator]);

      const signHashBytes = ethers.utils.arrayify(hasnVerify);

      if (library?.provider?.wc) {
        const wcMessage = ethers.utils.hexlify(signHashBytes);
        signVerify = await library.provider.wc.signPersonalMessage([wcMessage, creator]);
      } else {
        const signer = await library.getSigner(creator);
        signVerify = await signer.signMessage(signHashBytes);
      }
      return signVerify;
    } catch (error: any) {
      console.log('error :>> ', error);
      if (WALLET_STATUS.CANCEL_METAMASK === error?.code) {
        cancelMetamask && cancelMetamask();
      } else {
      }
    }
  };

  cancelSellOrder = async ({
    account,
    library,
    data,
    onCancelMetamask,
    onCallback,
    onError,
  }: {
    account?: string;
    library?: any;
    data?: Array<any> | any;
    onCancelMetamask?: () => void;
    onCallback?: (hash?: any) => void;
    onError?: () => void;
  }) => {
    const contract = getContract(PROXY_ADDRESS, ExchangeABI.output.abi, library, account);

    try {
      const response = await contract.handleCancelOrder(...data);
      if (response?.hash) {
        const receipt = await response.wait();
        if (receipt?.status) {
          onCallback && onCallback({ hash: receipt?.transactionHash, status: NFT_TRANSACTION_STATUS.SUCCESS });
        } else {
        }
      }
    } catch (error: any) {
      console.log('error :>> ', error);
      if (WALLET_STATUS.CANCEL_METAMASK === error?.code) {
        onCancelMetamask && onCancelMetamask();
      } else {
        onError && onError();
      }
    }
  };

  mintNFT = async ({
    account,
    library,
    data,
    onCancelMetamask,
    onCallback,
    onError,
  }: {
    account?: string;
    library?: any;
    data?: Array<any> | any;
    onCancelMetamask?: () => void;
    onCallback?: (hash?: any) => void;
    onError?: () => void;
  }) => {
    const contract = getContract(PROXY_ADDRESS, ExchangeABI.output.abi, library, account);

    try {
      const response = await contract.handleMintRequestByAdmin(...data);
      if (response?.hash) {
        const receipt = await response.wait();
        if (receipt?.status) {
          onCallback && onCallback({ hash: receipt?.transactionHash, status: NFT_TRANSACTION_STATUS.SUCCESS });
        } else {
          onError && onError();
        }
      }
    } catch (error: any) {
      if (WALLET_STATUS.CANCEL_METAMASK === error?.code) {
        onCancelMetamask && onCancelMetamask();
      } else {
        onError && onError();
      }
    }
  };
}
