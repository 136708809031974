import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Col, Row } from 'antd';

import AppButton from 'components/AppButton';
import FormItem, { TYPE_INPUT } from 'components/FormItem';
import NumberFormat from 'components/NumberFormat';

import { useGetConfig } from 'hooks/useGetConfig';
import { limitMaxlengNumber } from 'utils';
import { DOLLAR_TEXT } from 'constants/common';
import {
  MAX_LENGTH_TOTAL_SUPPLY,
  NFT_DECIMAL_SCALE,
  NFT_DECIMAL_SCALE_PRICE,
  NFT_DEFAULT_CREATE_FIELD,
  NFT_DEFAULT_PRICE,
  NFT_POSITIVE_SCALE,
} from 'constants/nft';

const { QUANTITY, UNIT_PRICE } = NFT_DEFAULT_CREATE_FIELD;

type SaleOrderFormProps = {
  maxOnSaleQuantity: number;
};

const SaleOrderForm = ({ maxOnSaleQuantity }: SaleOrderFormProps) => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext() as any;
  const { currency } = useGetConfig();

  const unitPrice = values?.[UNIT_PRICE];
  const unitCurrency = currency?.usd;

  const handleSetMaxQuantity = () => setFieldValue(QUANTITY, maxOnSaleQuantity);

  return (
    <Row gutter={60}>
      <Col span={24}>
        <FormItem
          typeInput={TYPE_INPUT.NUMBER}
          name={QUANTITY}
          placeholder={t('nft_list_for_sale.txt_on_sale_quantity_placeholder')}
          required
          thousandSeparator
          decimalScale={NFT_POSITIVE_SCALE}
          description={t('nft_list_for_sale.txt_on_sale_quantity_description')}
          label={t('nft_list_for_sale.txt_on_sale_quantity')}
          isAllowed={limitMaxlengNumber(MAX_LENGTH_TOTAL_SUPPLY)}
          appendInput={
            <AppButton
              text={t('nft_list_for_sale.txt_max')}
              className="field__button"
              onClick={handleSetMaxQuantity}
              variant="primary"
            />
          }
        />
      </Col>
      <Col span={24}>
        <FormItem
          typeInput={TYPE_INPUT.NUMBER}
          name={UNIT_PRICE}
          thousandSeparator
          placeholder={t('nft_list_for_sale.txt_listing_price_placeholder')}
          required
          className="content__listing"
          decimalScale={NFT_DECIMAL_SCALE_PRICE}
          description={t('nft_list_for_sale.txt_listing_price_description')}
          label={t('nft_list_for_sale.txt_listing_price')}
          isAllowed={limitMaxlengNumber()}
          appendInput={
            <div className="field__currency">
              <img src={currency.icon} />
              <span>{currency?.symbol}</span>
            </div>
          }
        >
          <p className="content__price">
            {unitPrice && unitCurrency ? (
              <>
                {DOLLAR_TEXT}
                <NumberFormat
                  thousandSeparator
                  value={unitPrice * unitCurrency}
                  displayType="text"
                  decimalScale={NFT_DECIMAL_SCALE}
                />
              </>
            ) : (
              NFT_DEFAULT_PRICE
            )}
          </p>
        </FormItem>
      </Col>
    </Row>
  );
};

export default SaleOrderForm;
