import ExcelJS from 'exceljs';
import moment from 'moment';

import { NFT_MARKET_CHANNEL } from 'constants/nft';

export const exportExcel = async ({ from, until, result, saleOrderType, t }: any) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Export Data');
  const getWidthByChannel = saleOrderType == NFT_MARKET_CHANNEL[2].value ? 30 : 50;

  worksheet.columns = [
    { width: 15 },
    { width: 35 },
    { width: 25 },
    { width: 15 },
    { width: 45 },
    { width: getWidthByChannel },
    { width: 35 },
    { width: 35 },
    { width: 15 },
    { width: 15 },
    { width: 15 },
    { width: 15 },
    { width: 30 },
    { width: 15 },
  ];

  const fontHeader = {
    name: 'Arial',
    bold: true,
    size: 20,
  };
  const commonFontBold = {
    name: 'Arial',
    bold: true,
    size: 16,
  };
  const commonFontNormal = {
    name: 'Arial',
    size: 14,
  };
  NFT_MARKET_CHANNEL;

  const typeSaleOrder = (saleOrderType: any) => {
    switch (saleOrderType) {
      case NFT_MARKET_CHANNEL[0].value:
        return t(NFT_MARKET_CHANNEL[0].name);
      case NFT_MARKET_CHANNEL[1].value:
        return t(NFT_MARKET_CHANNEL[1].name);
      case NFT_MARKET_CHANNEL[2].value:
        return t(NFT_MARKET_CHANNEL[2].name);
      default: {
        return null;
      }
    }
  };

  // create label
  worksheet.getCell('E2').value = t('export_excel.txt_revenue_statement');
  worksheet.getCell('E2').font = fontHeader;

  worksheet.getCell('A4').value = t('export_excel.txt_ekoios_marketplace');
  worksheet.getCell('A4').font = commonFontBold;
  worksheet.getCell('A5').value = `${t('export_excel.txt_network')}:`;
  worksheet.getCell('A5').font = commonFontBold;
  worksheet.getCell('A6').value = `${t('export_excel.txt_export_date')}:`;
  worksheet.getCell('A6').font = commonFontBold;

  worksheet.getCell('B5').value = t('export_excel.txt_binance_smart_chain');
  worksheet.getCell('B5').font = commonFontNormal;
  worksheet.getCell('B6').value = moment(new Date()).format('DD-MM-YYYY');
  worksheet.getCell('B6').font = commonFontNormal;

  worksheet.getCell('E5').value = `${t('export_excel.txt_start_date')}:`;
  worksheet.getCell('E5').font = commonFontBold;
  worksheet.getCell('E6').value = `${t('export_excel.txt_end_date')}:`;
  worksheet.getCell('E6').font = commonFontBold;

  worksheet.getCell('F5').value = moment(from).format('DD-MM-YYYY');
  worksheet.getCell('F5').font = commonFontNormal;
  worksheet.getCell('F6').value = moment(until).format('DD-MM-YYYY');
  worksheet.getCell('F6').font = commonFontNormal;

  worksheet.getCell('H5').value = `${t('export_excel.txt_market_channel')}:`;
  worksheet.getCell('H5').font = commonFontBold;

  worksheet.getCell('I5').value = typeSaleOrder(saleOrderType);
  worksheet.getCell('I5').font = commonFontNormal;

  // prepare data to table
  const listData = [];
  for (let i = 0; i < result?.length; i++) {
    const isPrimarySale = result[i]?.saleOrder?.type == NFT_MARKET_CHANNEL[1].value;
    const rawData = [
      i + 1,
      moment(result[i]?.createdAt).format('DD-MM-YYYY HH:mm:ss'),
      isPrimarySale ? t(NFT_MARKET_CHANNEL[1].name) : t(NFT_MARKET_CHANNEL[2].name),
      result[i]?.nft.code,
      result[i]?.nft?.name,
      isPrimarySale ? result[i]?.tokenIds.join(', ') : result[i]?.saleOrder?.tokenId,
      result[i]?.fromAddress,
      result[i]?.toAddress,
      result[i]?.quantity,
      result[i]?.saleOrder?.currency?.symbol,
      result[i]?.saleOrder?.unitPrice,
      result[i]?.subTotal,
      isPrimarySale ? '' : result[i]?.revenue,
      result[i]?.revenue,
    ];

    listData.push(rawData);
  }

  const columns = [
    { name: t('export_excel.txt_no') },
    { name: t('export_excel.txt_sale_date') },
    { name: t('export_excel.txt_market_channel_captain') },
    { name: t('export_excel.txt_nft_id') },
    { name: t('export_excel.txt_nft_name') },
    { name: t('export_excel.txt_token_id') },
    { name: t('export_excel.txt_seller_address') },
    { name: t('export_excel.txt_buyer_address') },
    { name: t('export_excel.txt_quantity') },
    { name: t('export_excel.txt_currency') },
    { name: t('export_excel.txt_price') },
    { name: t('export_excel.txt_subtotal') },
    { name: t('export_excel.txt_royalty_amount') },
    { name: t('export_excel.txt_revenue') },
  ];

  //create table
  worksheet.addTable({
    name: 'MyTable',
    ref: 'A10',
    columns,
    rows: listData,
  });

  // style for table
  worksheet.eachRow(function (row: any, rowNumber: number) {
    row.eachCell((cell: any, colNumber: number) => {
      // style for header
      if (rowNumber == 10) {
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        cell.font = {
          bold: true,
          size: 16,
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'd9e2f3' },
        };
        row.height = 25;
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      // style for row
      if (rowNumber > 10) {
        if (colNumber === 6 || colNumber === 7 || colNumber === 8) {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
            wrapText: true,
          };
        } else {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };
        }
        cell.font = {
          bold: false,
          size: 14,
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    });
    row.commit();
  });
  // save file

  return workbook.xlsx.writeBuffer();

  // end export
};
