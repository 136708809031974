import { CURRENCY } from './revenue';

export const NFT_PREFIX_CODE = '#';
export const NFT_PERCENTAGE_SUFFIX = '%';

export const NFT_MEDIA = {
  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
  MODEL: '3d',
};

export const NFT_TABS = {
  HERO: {
    key: 'HERO',
    label: 'nft_management.txt_hero',
    type: 'Hero',
  },
  ITEM: {
    key: 'ITEM',
    label: 'nft_management.txt_item',
    type: 'Item',
  },
};

export const NFT_DETAIL_TABS = {
  PROFILE: {
    key: 'PROFILE',
    label: 'nft_detail.txt_profile',
    query: 'nft-profile',
  },
  SALE_HISTORY: {
    key: 'SALE_HISTORY',
    label: 'nft_detail.txt_sale_history',
    query: 'sale-history',
  },
};

export const NFT_PROFILE_TABS = {
  PREVIEW: {
    key: 'PREVIEW',
    label: 'nft_detail.txt_preview',
  },
  CONTENT: {
    key: 'CONTENT',
    label: 'nft_detail.txt_content',
  },
};

export const NFT_STATUS = [
  {
    name: 'nft_management.txt_offsale',
    value: 'off-sale',
  },
  {
    name: 'nft_management.txt_onsale',
    value: 'on-sale',
  },
  {
    name: 'nft_management.txt_soldout',
    value: 'sold-out',
  },
];

export const LIST_SUPPORT_FILE = [
  'image/png',
  'image/jpeg',
  'image/svg+xml',
  'image/gif',
  'audio/mpeg',
  'audio/ogg',
  'audio/wav',
  'audio/webm',
  'video/ogg',
  'video/mp4',
  'video/webm',
  '3d/glb',
];

export const LIST_PREVIEW_SUPPORT_FILE = ['image/png', 'image/jpeg', 'image/svg+xml', 'image/gif'];

export const EXTENSION_3D_SUPPORT_ARRAY = ['3d/glb'];

export const MAX_FILE_SIZE = 100;
export const MAX_PREVIEW_SIZE = 2;

export const MAX_FILE_NUMBER = 1;
export const MIN_VALUE_TOTAL_COPIES = 1;
export const MAX_VALUE_TOTAL_COPIES = 1000000;
export const MAX_LENGTH_DESCRIPTION = 3000;
export const MAX_VALUE_ROYALTY_FEE = 50;
export const MAX_LENGTH_PRICE = 12;
export const MAX_LENGTH_TOTAL_SUPPLY = 7;
export const NFT_POSITIVE_SCALE = 0;
export const NFT_DECIMAL_SCALE = 2;
export const NFT_DECIMAL_SCALE_PRICE = 18;
export const NFT_USD_DECIMAL_SCALE = 8;

export const ATTRIBUTE_EXCLUDE = ['type'];

export const NFT_DEFAULT_ID = '#0000';

export const NFT_DEFAULT_PRICE = '$--';

export const LOCALIZATION_NFT_CREATION_ATTRIBUTE = {
  type: {
    text: 'nft_creation.txt_type',
    placeholder: 'nft_creation.txt_type_placeholder',
  },
  mythology: {
    text: 'nft_creation.txt_mythology',
    placeholder: 'nft_creation.txt_methology_placeholder',
  },
  god: {
    text: 'nft_creation.txt_god',
    placeholder: 'nft_creation.txt_god_placeholder',
  },
  level: {
    text: 'nft_creation.txt_level',
    placeholder: 'nft_creation.txt_level_placeholder',
  },
  class: {
    text: 'nft_creation.txt_class',
    placeholder: 'nft_creation.txt_class_placeholder',
  },
} as any;

export const LOCALIZATION_NFT_DETAIL_ATTRIBUTE = {
  mythology: {
    text: 'nft_creation.txt_mythology',
  },
  level: {
    text: 'nft_creation.txt_level',
  },
  class: {
    text: 'nft_creation.txt_class',
  },
  god: {
    text: 'nft_creation.txt_god',
  },
} as any;

export const NFT_DEFAULT_CREATE_FIELD = {
  FILE: 'file',
  FILE_PREVIEW: 'filePreview',
  NAME: 'name',
  ROYALTYFEE: 'royaltyFee',
  TOTAL_SUPPLY: 'totalSupply',
  DESCRIPTION: 'description',
  IS_PUT_ON_SALE: 'isPutOnSale',
  QUANTITY: 'quantity',
  UNIT_PRICE: 'unitPrice',
  CURRENCY: 'currency',
  IMAGE_MEDIUM: 'imageMedium',
  IMAGE_SMALL: 'imageSmall',
};

export const NFT_ATTRIBUTE_CREATED_FIELD = {
  TYPE: 'type',
  CLASS: 'class',
  GOD: 'god',
  MYTHOLOGY: 'mythology',
  LEVEL: 'level',
};

export const SERVER_PARAMS_CONFIG = {
  TOKEN: 'token',
  SALE_ORDER: 'saleOrder',
  ATTRIBUTES: 'attributes',
};

export const NFT_MINTED_FIELD = {
  QUANTITY: 'quantity',
  TO_ADDRESS: 'toAddress',
};

export const NFT_TRANSACTION_TYPE = {
  LISTED: 'listed',
  DELISTED: 'delisted',
  MINTED: 'minted',
  TRANSFER: 'transfer',
  ADMIN_MINTED: 'admin-minted',
};

export const NFT_TRANSACTION_STATUS = {
  DRAFT: 'draft',
  SUCCESS: 'success',
  CANCEL: 'cancel',
  FAILED: 'failed',
};

export const NFT_STANDARD = [
  { value: 'erc-721', key: 0, label: 'common.txt_erc_721' },
  { value: 'erc-1155', key: 1, label: 'common.txt_erc_1155' },
];

export const NFT_SALE_HISTORY_FIELD = {
  KEYWORD: 'keyword',
  FROM: 'from',
  UNTIL: 'until',
  TYPE: 'type',
  PAGE: 'page',
  LIMIT: 'limit',
  ORDER: 'order',
  SORT: 'sort',
};

export const NFT_SALE_HISTORY_FIELD_SORTER = {
  DEFAULT: 'default',
  CREATED_AT: 'createdAt',
  QUANTITY: 'quantity',
  UNIT_PRICE: 'saleOrder.unitPrice',
  SALE_ORDER: 'saleOrder',
  REVENUE: 'revenue',
};

export const NFT_MANAGEMENT_FIELD = {
  KEYWORD: 'keyword',
  STATUS: 'status',
  PAGE: 'page',
  LIMIT: 'limit',
  SORT: 'sort',
  TYPE: 'type',
};

export const NFT_MANAGEMENT_FIELD_SORTER = {
  DEFAULT: 'default',
  CREATED_AT: 'createdAt',
  NFT_ID: 'code',
  NFT_NAME: 'name',
  TOTAL_SUPPLY: 'totalSupply',
  TOTAL_MINTED: 'totalMinted',
  ON_SALE_QUANTITY: 'onSaleQuantity',
};

export const NFT_MARKET_CHANNEL = [
  {
    name: 'nft_detail.txt_all',
    value: null,
  },
  {
    name: 'nft_detail.txt_primary_sale',
    value: 'sell',
  },
  {
    name: 'nft_detail.txt_secondary_sale',
    value: 'resell',
  },
];

export const DASHBOARD_CURRENCY = [
  {
    name: CURRENCY,
    value: CURRENCY,
  },
  {
    name: 'USD',
    value: 'usd',
  },
];

export const DASHBOARD_DATA_TYPES = [
  {
    name: 'dashboard.txt_volume',
    value: 'volume',
  },
  {
    name: 'dashboard.txt_revenue',
    value: 'revenue',
  },
];

export const DASHBOARD_SALE_ANALYTICS = {
  CURRENCY: 'currency',
  DATA_TYPE: 'dataType',
  TIME_TYPE: 'timeType',
};

export const DASHBOARD_OVERVIEW = {
  TIME_TYPE: 'timeType',
};

export const DASHBOARD_TIME_TYPES = [
  { name: 'dashboard.txt_all_time', value: 'all-time' },
  { name: 'dashboard.txt_today', value: 'day' },
  { name: 'dashboard.txt_this_week', value: 'week' },
  { name: 'dashboard.txt_this_month', value: 'month' },
  { name: 'dashboard.txt_this_quarter', value: 'quarter' },
  { name: 'dashboard.txt_this_year', value: 'year' },
];

export const DASHBOARD_TIME_FORMAT = {
  HOUR: 'HH',
  YEAR: 'YYYY',
  MONTH_DAY: 'MMM DD',
  MONTH_YEAR: 'MMM YYYY',
  TOOLTIP: 'MMM DD YYYY, HH:mm',
};

export const DASHBOARD_TOP_NFTS_TABS = {
  HERO: {
    key: 'HERO',
    label: 'dashboard.txt_hero',
  },
  ITEM: { label: 'dashboard.txt_item', key: 'ITEM' },
};

export const DASHBOARD_TOP_NFTS_SORT = {
  TOTAL_VOLUME: 'sort[totalVolume]',
  DESC: 'desc',
};

export const REMOVE_FROM_SALE_STEPS = {
  PROCESSING: 1,
  SUCCESSFUL: 2,
  FAILED: 3,
};

export const PAGE_TAB_QUERY = 'tab';
