import { useEffect, useState } from 'react';
import omit from 'lodash/omit';

import { checkSusscessRequest, excludeResponse } from 'services/api';
import nftServices from 'services/nft';
import transactionServices from 'services/transaction';

import { NFT_SALE_HISTORY_FIELD, NFT_SALE_HISTORY_FIELD_SORTER } from 'constants/nft';
import { getEndDateTimestamp, getStartDateTimestamp, ORDERS } from 'utils';
import { ZERO_VALUE } from 'constants/common';

const { SORT } = NFT_SALE_HISTORY_FIELD;

const { DEFAULT, CREATED_AT, QUANTITY, UNIT_PRICE, SALE_ORDER, REVENUE } = NFT_SALE_HISTORY_FIELD_SORTER;

const { ASC, DESC, FIELD, ORDER } = ORDERS;

export const useDeleteNFT = (id: string, { onSuccess, onError }: { onSuccess: () => void; onError: () => void }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteNFT = async () => {
    setLoading(true);
    try {
      const response = await nftServices.handleDeleteNFT(id);
      if (checkSusscessRequest(response)) {
        onSuccess && onSuccess();
      } else {
        onError && onError();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onDeleteNFT: handleDeleteNFT,
  };
};

export const useGetOwnerListing = (id: string, params: any) => {
  const [loading, setLoading] = useState(false);
  const [listOwners, setListOwners] = useState([]);
  const [totalOwner, setTotalOwner] = useState(ZERO_VALUE);
  const [total, setTotal] = useState(ZERO_VALUE);

  useEffect(() => {
    handleGetOwnerListing();
  }, [id, params]);

  const handleGetOwnerListing = async () => {
    setLoading(true);
    try {
      const response = await nftServices.handleGetOwnerListing(id, params);
      if (checkSusscessRequest(response)) {
        const { docs = [], totalOwners = 0, totalDocs } = response?.data;
        setListOwners(docs);
        setTotal(totalDocs);
        setTotalOwner(totalOwners);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  return { data: listOwners, total, loading, totalOwner };
};

export const useCreateTransaction = () => {
  const handleCreateTransaction = async (
    data: any,
    { onSuccess, onError }: { onSuccess: (id: string, data: any) => void; onError: () => void },
  ) => {
    try {
      const response = await transactionServices.handleCreateTransaction(data);
      if (checkSusscessRequest(response)) {
        const dataRequest = response?.data?.signature?.dataRequest || [];
        onSuccess && onSuccess(response?.data?._id, dataRequest);
      } else {
        onError && onError();
      }
    } catch (error) {}
  };

  return {
    onCreateTransaction: handleCreateTransaction,
  };
};

export const useGetListSaleHistory = (id: string, params: any) => {
  const [loading, setLoading] = useState(false);
  const [listSales, setListSales] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState('');

  const INDEXED_SORTER = {
    [DEFAULT]: { [CREATED_AT]: DESC },
    [CREATED_AT]: { [CREATED_AT]: DESC },
    [QUANTITY]: { [QUANTITY]: ASC, [CREATED_AT]: DESC },
    [UNIT_PRICE]: { [UNIT_PRICE]: ASC, [CREATED_AT]: DESC },
    [SALE_ORDER]: { [SALE_ORDER]: ASC, [CREATED_AT]: DESC },
    [REVENUE]: { [REVENUE]: ASC, [CREATED_AT]: DESC },
  };

  useEffect(() => {
    handleGetListSaleHistory();
  }, [id, params]);

  const handleGetListSaleHistory = async () => {
    setLoading(true);
    try {
      const newParams = omit({ ...params }, [ORDERS.FIELD, ORDERS.ORDER]) as any;
      newParams.from = getStartDateTimestamp(params?.from);
      newParams.until = getEndDateTimestamp(params?.until);
      newParams.type = params?.type;
      const field = params?.[FIELD] || DEFAULT;

      for (const key in INDEXED_SORTER?.[field]) {
        if (key === field && params?.[ORDER] && params?.[FIELD]) {
          newParams[`${SORT}[${key}]`] = params?.[ORDER];
        } else {
          newParams[`${SORT}[${key}]`] = INDEXED_SORTER?.[field]?.[key];
        }
      }
      const response = await nftServices.handleGetListSaleHistory(id, newParams);
      if (checkSusscessRequest(response)) {
        const { docs = [], totalDocs = 0, totalRevenue } = response?.data;
        setListSales(docs);
        setTotal(totalDocs);
        setTotalRevenue(totalRevenue);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  return { data: listSales, total, loading, totalRevenue };
};

export const useUpdateTransaction = () => {
  const handleUpdateTransaction = async (
    id: string,
    data: any,
    { onSuccess, onError }: { onSuccess: () => void; onError: () => void },
  ) => {
    try {
      const response = await transactionServices.handleUpdateTransaction(id, data);
      if (checkSusscessRequest(response)) {
        if (!response?.isAlreadyCompleted) {
          onSuccess && onSuccess();
        }
      } else {
        if (!excludeResponse.includes(response?.data?.code)) {
          onError && onError();
        }
      }
    } catch (error) {}
  };

  return {
    onUpdateTransaction: handleUpdateTransaction,
  };
};
