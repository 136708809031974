import moment from 'moment';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

import {
  EXTENSION_3D_SUPPORT_ARRAY,
  MAX_LENGTH_PRICE,
  NFT_ATTRIBUTE_CREATED_FIELD,
  NFT_DECIMAL_SCALE,
  NFT_DEFAULT_CREATE_FIELD,
  NFT_MEDIA,
} from 'constants/nft';
import { EMPTY_DEFAULT_TEXT, IMAGE_TYPE, MAX_NFT_CODE_LENGTH, TOKEN_SUPPORT, ZERO_VALUE } from 'constants/common';
import LENGTH_CONSTANTS from 'constants/length';

const { MIN_VALUE } = LENGTH_CONSTANTS;

export const DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';

export const ANTD_ORDERS = {
  ASCEND: 'ascend',
  DESCEND: 'descend',
};

export const ORDERS = {
  ASC: 'asc',
  DESC: 'desc',
  FIELD: 'field',
  ORDER: 'order',
};

export const clearRequestParams = (params?: any) => {
  const newParams = {} as any;
  const cloneParams = { ...params };
  for (const field in cloneParams) {
    if (cloneParams?.[field] || cloneParams?.[field] === 0 || cloneParams?.[field] === false) {
      newParams[field] = cloneParams?.[field];
    }
  }
  return newParams;
};

const { FILE, FILE_PREVIEW, TOTAL_SUPPLY, IS_PUT_ON_SALE, CURRENCY, IMAGE_MEDIUM, IMAGE_SMALL } =
  NFT_DEFAULT_CREATE_FIELD;

export const formatCurrency = (
  value: any,
  options: { isNotCompare?: boolean; decimal?: number; isNotFormatDecimal?: boolean } = {},
) => {
  BigNumber.config({
    EXPONENTIAL_AT: 100,
  });

  const { isNotCompare, decimal = NFT_DECIMAL_SCALE, isNotFormatDecimal } = options;

  const formatDecimal = isNotFormatDecimal ? undefined : decimal;

  if (!value) {
    return ZERO_VALUE;
  }
  if (isNotCompare) {
    return new BigNumber(value).toFormat();
  }

  return new BigNumber(value).isLessThan(new BigNumber(MIN_VALUE))
    ? new BigNumber(MIN_VALUE).toFormat()
    : new BigNumber(value).toFormat(formatDecimal);
};

export const shortenAddress = (address: string, number = -4) => {
  if (address) {
    const first = address.slice(0, 6);
    const last = address.slice(number);
    return `${first}...${last}`;
  }
  return '--';
};

export const getCurrentTime = () => {
  return moment().valueOf();
};

export const formatDate = (date: moment.MomentInput | any, type = DATE_FORMAT) => {
  return moment(date).format(type);
};

export const setOrderSorter = (order: string | null | undefined) => {
  const newOrder =
    (order === ANTD_ORDERS.ASCEND && ORDERS.ASC) || (order === ANTD_ORDERS.DESCEND && ORDERS.DESC) || null;
  return newOrder;
};

export const get3DFileType = (fileName: string) => {
  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1)?.toLowerCase() || '';
  const type = `3d/${extension}`;
  if (EXTENSION_3D_SUPPORT_ARRAY.includes(type)) {
    return type;
  }
  return '';
};

export const getFullFormatedNFT = (value: any) => {
  return value?.fileList?.[0]?.type || get3DFileType(value?.fileList?.[0]?.name) || IMAGE_TYPE;
};

export const getFormatedNFT = (value: any) => {
  const fullFormat = getFullFormatedNFT(value);
  return fullFormat?.split('/')[0] || NFT_MEDIA.IMAGE;
};

export const limitPercentage = (inputObj: any) => {
  const MAX_PERCENT = 100;
  const { value } = inputObj;
  if (Number(value) > MAX_PERCENT) {
    return false;
  }
  return true;
};

export const limitMaxlengNumber =
  (maxLength: number = MAX_LENGTH_PRICE) =>
  (inputObj: any) => {
    const { value } = inputObj;
    const integerPath = (value || '').split('.')[0];
    return integerPath.length <= maxLength;
  };

export const getAttributeFieldNFTValues = (values: any) => {
  return Object.values(NFT_ATTRIBUTE_CREATED_FIELD).reduce((acc: any, field: string | any) => {
    acc[field] = values?.attributes?.[field]?.text || values?.attributes?.[field];
    return acc;
  }, {});
};

export const getDefaultFieldNFTValues = (values: any) => {
  return Object.values(NFT_DEFAULT_CREATE_FIELD).reduce((acc: any, field: string | any) => {
    switch (field) {
      case FILE:
        acc[FILE] = {
          fileList: [{ type: values?.media?.type || IMAGE_TYPE }],
          previewContent: values?.media?.url || values?.image?.url || '',
        };
        break;
      case FILE_PREVIEW:
        acc[FILE_PREVIEW] = {
          fileList: [],
          previewContent: values?.image?.url || '',
        };
        break;
      case IMAGE_MEDIUM:
        acc[IMAGE_MEDIUM] = values?.image?.mediumUrl;
        break;
      case IMAGE_SMALL:
        acc[IMAGE_SMALL] = values?.image?.smallUrl;
        break;
      case TOTAL_SUPPLY:
        acc[TOTAL_SUPPLY] = values?.token?.[TOTAL_SUPPLY]?.toString();
        break;
      case IS_PUT_ON_SALE:
        acc[IS_PUT_ON_SALE] = false;
        break;
      case CURRENCY:
        acc[CURRENCY] = TOKEN_SUPPORT.value;
        break;
      default:
        acc[field] = values?.[field]?.toString() || '';
        break;
    }
    return acc;
  }, {});
};

export const getFieldValues = (values: any, objectField: any = NFT_ATTRIBUTE_CREATED_FIELD) => {
  return Object.values(objectField).reduce((acc: any, field: string | any) => {
    if (field === NFT_DEFAULT_CREATE_FIELD.FILE || field === NFT_DEFAULT_CREATE_FIELD.FILE_PREVIEW) {
      acc[field] = values?.[field]?.previewContent;
    } else {
      acc[field] = values?.[field];
    }
    return acc;
  }, {});
};

export const checkValueNftChange = (preVal: object, newVal: object, isEditing?: boolean) => {
  let newPrevNft = { ...preVal };

  if (isEditing) {
    newPrevNft = {
      ...getDefaultFieldNFTValues(preVal),
      ...getAttributeFieldNFTValues(preVal),
    };
  }

  // prev
  const defaultPrevValues = getFieldValues(newPrevNft, NFT_DEFAULT_CREATE_FIELD) as object;
  const attributePrevValues = getFieldValues(newPrevNft) as object;

  // new
  const defaultNewValues = getFieldValues(newVal, NFT_DEFAULT_CREATE_FIELD) as object;
  const attributeNewValues = getFieldValues(newVal) as object;

  const prevNft = { ...defaultPrevValues, ...attributePrevValues };
  const newNft = { ...defaultNewValues, ...attributeNewValues };

  return JSON.stringify(prevNft) !== JSON.stringify(newNft);
};

export const formatPadStart = (value: any) => {
  if (!value) {
    return EMPTY_DEFAULT_TEXT;
  }
  return value.length > MAX_NFT_CODE_LENGTH ? shortenAddress(value) : value;
};

export const getNumberValue = (value?: number) => {
  return value ?? ZERO_VALUE;
};

export const getValueAttribute = (attributes: any, field: string) => attributes?.[field]?.text || attributes?.[field];

export const getImageAttribute = (attributes: any, field: string) => attributes?.[field]?.imgUrl;

export const getRowKey = (row: any) => row?._id;

export const nFormatter = (num: any, digits: any = 2) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : num.toFixed(8);
};

export const getStartDateTimestamp = (value: any) => {
  if (!value) {
    return;
  }
  return moment(value).clone().startOf('days').toISOString();
};

export const getEndDateTimestamp = (value: any) => {
  if (!value) {
    return;
  }
  return moment(value).clone().endOf('days').toISOString();
};

export const getDuration = (from: any, until: any) => {
  const duration = moment.duration(until.diff(from));
  return duration;
};

export const disabledFromDate = (date: any) => (current: moment.Moment) => {
  return (date && current?.clone()?.endOf('day') > date?.clone()?.endOf('day')) || current > moment();
};

export const disabledUntilDate = (date: any) => (current: moment.Moment) => {
  return (date && current?.clone()?.startOf('day') < date?.clone()?.startOf('day')) || current > moment();
};

export const isAddress = (address: string) => {
  let exactAddress;
  try {
    exactAddress = Web3.utils.toChecksumAddress(address?.trim());
  } catch (error) {}
  return address?.toLowerCase() === exactAddress?.toLowerCase();
};

export const removeTrailingZeros = (num: any, fixed: any = 2) => {
  const numberReg = new RegExp(`^-?\\d+(?:.\\d{0,${fixed}})?`);
  return num
    ? num
        .toString()
        .match(numberReg)[0]
        .replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1')
    : ZERO_VALUE;
};
