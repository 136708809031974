import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';

import Card from 'components/AppCard';
import FormItem, { TYPE_INPUT } from 'components/FormItem';
import NumberFormat from 'components/NumberFormat';
import AppButton from 'components/AppButton';

import { useGetConfig } from 'hooks/useGetConfig';
import { limitMaxlengNumber } from 'utils';
import {
  MAX_LENGTH_TOTAL_SUPPLY,
  NFT_DECIMAL_SCALE,
  NFT_DECIMAL_SCALE_PRICE,
  NFT_DEFAULT_CREATE_FIELD,
  NFT_DEFAULT_PRICE,
  NFT_POSITIVE_SCALE,
} from 'constants/nft';

const { QUANTITY, TOTAL_SUPPLY, IS_PUT_ON_SALE, UNIT_PRICE } = NFT_DEFAULT_CREATE_FIELD;

const NFTListForSale = () => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext() as any;
  const { currency } = useGetConfig();

  const unitPrice = values?.[UNIT_PRICE];
  const unitCurrency = currency?.usd;

  const handleSetMaxQuantity = () => setFieldValue(QUANTITY, values?.[TOTAL_SUPPLY]);

  const handleSwitchChange = (checked: boolean) => {
    if (!checked) {
      setFieldValue(QUANTITY, '');
      setFieldValue(UNIT_PRICE, '');
    }
  };

  return (
    <Card className="nft-creation-page-list-for-sale">
      <div className="nft-creation-page-list-for-sale__header">
        <span className="title">{t('nft_creation.txt_list_for_sale')}</span>
        <FormItem typeInput={TYPE_INPUT.SWITCH} name={IS_PUT_ON_SALE} onChange={handleSwitchChange} />
      </div>
      {values?.[IS_PUT_ON_SALE] && (
        <Row className="nft-creation-page-list-for-sale__content" gutter={20}>
          <Col md={12} xs={24}>
            <FormItem
              typeInput={TYPE_INPUT.NUMBER}
              name={QUANTITY}
              placeholder={t('nft_creation.txt_edition_quantity_placeholder')}
              required
              thousandSeparator
              decimalScale={NFT_POSITIVE_SCALE}
              description={t('nft_creation.txt_edition_quantity_sub')}
              label={t('nft_creation.txt_onsale_quantity')}
              isAllowed={limitMaxlengNumber(MAX_LENGTH_TOTAL_SUPPLY)}
              appendInput={
                <AppButton
                  text={t('nft_creation.txt_max')}
                  className="field__button"
                  onClick={handleSetMaxQuantity}
                  variant="primary"
                  disabled={!values?.[TOTAL_SUPPLY]}
                />
              }
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItem
              typeInput={TYPE_INPUT.NUMBER}
              name={UNIT_PRICE}
              thousandSeparator
              placeholder={t('nft_creation.txt_listing_price_placeholder')}
              required
              className="content__listing"
              decimalScale={NFT_DECIMAL_SCALE_PRICE}
              description={t('nft_creation.txt_listing_price_sub')}
              label={t('nft_creation.txt_listing_price')}
              isAllowed={limitMaxlengNumber()}
              appendInput={
                <div className="field__currency">
                  <img src={currency.icon} />
                  <span>{currency?.symbol}</span>
                </div>
              }
            >
              <p className="content__price">
                {unitPrice && unitCurrency ? (
                  <>
                    $
                    <NumberFormat
                      thousandSeparator
                      value={unitPrice * unitCurrency}
                      displayType="text"
                      decimalScale={NFT_DECIMAL_SCALE}
                    />
                  </>
                ) : (
                  NFT_DEFAULT_PRICE
                )}
              </p>
            </FormItem>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default NFTListForSale;
