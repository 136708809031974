import { api } from 'services/api';

import { NFTParamsTypes } from 'pages/nft-management';

class NFTServices {
  handleGetList = (params: NFTParamsTypes) => {
    return api.get('admin/nfts', params);
  };

  handleCreateNFT = (data: any) => {
    return api.postMultiplePart('admin/nfts', data);
  };

  handleUpdateNFT = (data: any, id: string) => {
    return api.patchMultipart(`admin/nfts/${id}`, data);
  };

  handleDeleteNFT = (id: string) => {
    return api.delete(`admin/nfts/${id}`);
  };

  handleGetNftDetail = (id: string) => {
    return api.get(`admin/nfts/${id}`);
  };

  handleGetOwnerListing = (id: string, params: any) => {
    return api.get(`admin/nfts/${id}/owner`, params);
  };

  handleListForSaleNFT = (id: string, data: any) => {
    return api.post(`admin/nfts/${id}/sale-orders`, data);
  };

  handleGetListSaleHistory = (id: string, params: any) => {
    return api.get(`admin/nfts/${id}/transactions`, params);
  };
}

const nftServices = new NFTServices();

export default nftServices;
