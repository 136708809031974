import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchForm from '../SearchForm';
import Sale from '../Sale';
import List from '../List';
import PageContent from 'components/PageContent';
import { useGetRevenue } from 'pages/revenue-management/hooks';

import AppIcon from 'resources/svg/app_icon.svg';

import { formatCurrency, getNumberValue, nFormatter } from 'utils';
import LENGTH_CONSTANTS from 'constants/length';
import { COLUMN, CURRENCY, REVENUE_FIELD } from 'constants/revenue';

const { PAGE, LIMIT, SELL, KEYWORD, TYPE, FROM, UNTIL } = REVENUE_FIELD;
const { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } = LENGTH_CONSTANTS;
const {
  NO,
  SELLER,
  SALE_DATE,
  BUYER,
  QUANTITY,
  NFT_ID,
  PRICE,
  NFT_NAME,
  SUBTOTAL,
  ACTIONS,
  TYPE: TYPE_COLUMN,
} = COLUMN;

const defaultValue = [NO, SALE_DATE, QUANTITY, PRICE, NFT_NAME, SUBTOTAL, ACTIONS];

const initialValues = {
  [KEYWORD]: '',
  [TYPE]: SELL,
  [FROM]: null,
  [UNTIL]: null,
  [PAGE]: DEFAULT_PAGE,
  [LIMIT]: DEFAULT_PAGE_SIZE,
};

const Primary = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initialValues);
  const { data, total, loading, dataSale } = useGetRevenue(params) as any;
  const [primaryColumn, setPrimaryColumn] = useState(defaultValue);

  const primarySaleItem = [
    {
      label: 'revenue_management.txt_primary_sale_revenue',
      value: formatCurrency(getNumberValue(dataSale?.revenue?.primarySale), { isNotCompare: true }),
      toolTip: t('revenue_management.txt_primary_revenue_tooltip'),
      appIcon: AppIcon,
      currency: CURRENCY,
      usd: getNumberValue(dataSale?.revenue?.primarySaleUsd),
    },
    {
      label: 'revenue_management.txt_primary_sale_volume',
      value: formatCurrency(getNumberValue(dataSale?.volume?.primarySale), { isNotCompare: true }),
      toolTip: t('revenue_management.txt_primary_volume_tooltip'),
      appIcon: AppIcon,
      currency: CURRENCY,
      usd: getNumberValue(dataSale?.volume?.primarySaleUsd),
    },
    {
      label: 'revenue_management.txt_primary_transactions',
      value: nFormatter(total),
    },
    {
      label: 'revenue_management.txt_primary_nfts_sold',
      value: nFormatter(getNumberValue(dataSale?.nftSold)),
    },
  ];

  const primaryColumnsItem = [
    { label: t('revenue_management.txt_no'), value: NO, disabled: true },
    { label: t('revenue_management.txt_seller'), value: SELLER },
    { label: t('revenue_management.txt_sale_date'), value: SALE_DATE },
    { label: t('revenue_management.txt_buyer'), value: BUYER },
    { label: t('revenue_management.txt_type'), value: TYPE_COLUMN },
    { label: t('revenue_management.txt_quantity'), value: QUANTITY },
    { label: t('revenue_management.txt_nft_id'), value: NFT_ID },
    { label: t('revenue_management.txt_price'), value: PRICE },
    { label: t('revenue_management.txt_nft_name'), value: NFT_NAME },
    { label: t('revenue_management.txt_subtotal'), value: SUBTOTAL },
    { label: t('revenue_management.txt_actions'), value: ACTIONS, disabled: true },
  ];

  const getListColumn = (listColumn: any) => setPrimaryColumn(listColumn);

  const handleChangePaging = (page: number, limit: number) => {
    setParams({
      ...params,
      [PAGE]: limit !== params?.limit ? DEFAULT_PAGE : page,
      [LIMIT]: limit,
    });
  };

  return (
    <PageContent>
      <SearchForm
        onSetParams={setParams}
        params={params}
        getListColumn={getListColumn}
        listColumn={primaryColumn}
        initialValues={initialValues}
        columnsItem={primaryColumnsItem}
      />
      <Sale saleItem={primarySaleItem} />
      <List
        total={total}
        listSale={data}
        loading={loading}
        page={params?.page as any}
        limit={params?.limit as any}
        params={params}
        listColumn={primaryColumn}
        handleChangePaging={handleChangePaging}
        onSetParams={setParams}
      />
    </PageContent>
  );
};

export default Primary;
