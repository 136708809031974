import React, { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'antd';

import EllipsisText from 'components/EllipsisText';
import NumberFormat from 'components/NumberFormat';

import VideoIcon from 'resources/svg/video_icon.svg';
import NftDefault from 'resources/images/nft_default.png';

import selectedConfig from 'redux/config/selector';
import { useAppSelector } from 'hooks/useStore';
import { getFormatedNFT, formatPadStart } from 'utils';
import { NFT_ATTRIBUTE_CREATED_FIELD, NFT_DEFAULT_CREATE_FIELD, NFT_DEFAULT_ID, NFT_MEDIA } from 'constants/nft';

const { MYTHOLOGY, CLASS, LEVEL, GOD } = NFT_ATTRIBUTE_CREATED_FIELD;

type PreviewNFTProps = {
  nft: any;
  previewSrc: string;
  code?: string;
  children?: ReactNode;
  isPreview?: boolean;
};

const PreviewNFT = ({ nft, previewSrc, code = NFT_DEFAULT_ID, children, isPreview = true }: PreviewNFTProps) => {
  const { t } = useTranslation();
  const { general = {} } = useAppSelector(selectedConfig.getConfig);
  const { attributes = [] } = general;
  const nftFormat = getFormatedNFT(nft?.[NFT_DEFAULT_CREATE_FIELD.FILE]);

  const mythologyNFT = attributes.find((attribute: any) => attribute?.name === MYTHOLOGY)?.value || [];
  const mythologyImg = mythologyNFT.find((mythology: any) => mythology?.text === nft?.[MYTHOLOGY])?.imgUrl;

  const levelNFT = attributes.find((attribute: any) => attribute?.name === LEVEL)?.value || [];
  const classImg = levelNFT.find((level: any) => level?.text === nft?.[LEVEL])?.imgUrl;

  return (
    <div className="preview-image preview-nft">
      <div className="preview-nft__header">
        <div className="info">
          <EllipsisText text={formatPadStart(code)} className="info__id" />
          {nft?.[CLASS] && (
            <Fragment>
              <div className="info__dot" />
              <span className="info__class">{nft?.[CLASS]}</span>
            </Fragment>
          )}
        </div>
        <div className="skill">
          {classImg && <img src={classImg} className="image--first" />}
          {mythologyImg && <img src={mythologyImg} className="image--second" />}
        </div>
      </div>
      <div className="preview-nft__content">
        <div className="info">
          <Image src={previewSrc || NftDefault} className="info__image" preview={isPreview} />
          <div className="info__footer">
            {nft?.[GOD] ? <EllipsisText text={nft?.[GOD]} className="text" /> : <div>&nbsp;</div>}
            {nftFormat !== NFT_MEDIA.IMAGE && <img src={VideoIcon} />}
          </div>
        </div>
        <div className="footer">
          <EllipsisText text={nft?.[NFT_DEFAULT_CREATE_FIELD.NAME]} className="footer__text" />
          {nft?.[NFT_DEFAULT_CREATE_FIELD.TOTAL_SUPPLY] && (
            <div className="footer__sub">
              {t('nft_creation.txt_edition')}&nbsp;
              {nft?.[NFT_DEFAULT_CREATE_FIELD.QUANTITY] && (
                <Fragment>
                  <NumberFormat thousandSeparator value={nft?.[NFT_DEFAULT_CREATE_FIELD.QUANTITY]} displayType="text" />
                  &nbsp;
                </Fragment>
              )}
              {t('nft_creation.txt_of')}&nbsp;
              <NumberFormat thousandSeparator value={nft?.[NFT_DEFAULT_CREATE_FIELD.TOTAL_SUPPLY]} displayType="text" />
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default PreviewNFT;
