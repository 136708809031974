import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import PageContent from 'components/PageContent';
import AppButton from 'components/AppButton';
import AppTab from 'components/AppTab';
import Hero from './components/Hero';
import Item from './components/Item';

import LENGTH_CONSTANTS from 'constants/length';
import { routeURLs } from 'constants/routes';
import { NFT_MANAGEMENT_FIELD, NFT_TABS } from 'constants/nft';

const { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } = LENGTH_CONSTANTS;
const { KEYWORD, STATUS, PAGE, LIMIT, TYPE } = NFT_MANAGEMENT_FIELD;

export interface NFTParamsTypes {
  keyword: string;
  status: string | number | null;
  page: number;
  limit: number;
}

const initParams = {
  [KEYWORD]: '',
  [STATUS]: null,
  [PAGE]: DEFAULT_PAGE,
  [LIMIT]: DEFAULT_PAGE_SIZE,
};

const NFTManagement = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(NFT_TABS.HERO.key);

  // hero
  const [heroTab, setHeroTab] = useState({
    params: { ...initParams, [TYPE]: NFT_TABS.HERO.type },
  });

  // item
  const [itemTab, setItemTab] = useState({
    params: { ...initParams, [TYPE]: NFT_TABS.ITEM.type },
  });

  const handleChangeTab = (value: string) => setActiveTab(value);

  const handleSubmit = (values: any) => {
    const fncTab = activeTab === NFT_TABS.HERO.key ? setHeroTab : setItemTab;
    const objTab = activeTab === NFT_TABS.HERO.key ? heroTab : itemTab;
    fncTab({
      ...objTab,
      params: {
        ...objTab?.params,
        ...values,
      },
    });
  };

  const handleRedirectNFTCreationPage = () => history.push(routeURLs.NFT_CREATION);

  const listTab = [
    {
      key: NFT_TABS.HERO.key,
      tab: t(NFT_TABS.HERO.label),
      content: <Hero values={heroTab} onSubmit={handleSubmit} />,
    },
    {
      key: NFT_TABS.ITEM.key,
      tab: t(NFT_TABS.ITEM.label),
      content: <Item values={itemTab} onSubmit={handleSubmit} />,
    },
  ];

  return (
    <div className="nft-management-page">
      <div className="nft-management-page__header">
        <PageHeader title={t('nft_management.txt_nft_management')} />
        <AppButton
          text={`+ ${t('nft_management.txt_create_nft')}`}
          onClick={handleRedirectNFTCreationPage}
          variant="primary"
          className="nft-management-page__button"
        />
      </div>
      <div className="nft-management-page__app-tab">
        <AppTab onChangeTab={handleChangeTab} activeKey={activeTab} listTab={listTab} />
      </div>
    </div>
  );
};

export default NFTManagement;
