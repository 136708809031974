import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Checkbox } from 'antd';
import { Form, Formik } from 'formik';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import trim from 'lodash/trim';
import isString from 'lodash/isString';
import { useTranslation } from 'react-i18next';

import AppDropdown from 'components/AppDropdown';
import FormItem, { TYPE_INPUT } from 'components/FormItem';
import AppButton from 'components/AppButton';
import EllipsisText from 'components/EllipsisText';

import RefreshIcon from 'resources/svg/refresh_icon.svg';
import SettingIcon from 'resources/svg/setting_icon.svg';

import { useChangeAddress } from 'hooks/useChangeAddress';
import { disabledFromDate, disabledUntilDate } from 'utils';
import LENGTH_CONSTANTS from 'constants/length';
import { REVENUE_FIELD, REVENUE_TYPE } from 'constants/revenue';

const { DEFAULT_PAGE, MAX_COLUMNS } = LENGTH_CONSTANTS;

const { KEYWORD, FROM, UNTIL, PAGE, LIMIT, STANDARD } = REVENUE_FIELD;

const SearchForm = ({ onSetParams, params, getListColumn, listColumn, initialValues, columnsItem }: any) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [columnSelected, setColumnSelected] = useState(listColumn);
  const formRef = useRef<any>(null);

  const isDisabledApply = columnSelected.length > MAX_COLUMNS;

  const optionSelect = REVENUE_TYPE.map((type) => ({
    ...type,
    name: t(type?.name),
  }));

  const initialParams = omit({ ...initialValues }, [PAGE, LIMIT]) as any;

  const handleResetForm = () => {
    formRef?.current?.resetForm();
    onSetParams({
      ...initialParams,
      ...initialValues,
      [PAGE]: DEFAULT_PAGE,
    });
  };

  useChangeAddress({ callback: handleResetForm });

  const handleFieldChange = (setFieldValue: any, name: string) => (value: any) => {
    setFieldValue(name, isString(value) ? trim(value) : value);
    onSetParams({
      ...params,
      [name]: isString(value) ? trim(value) : value,
      [PAGE]: DEFAULT_PAGE,
    });
  };

  const handleChecked = (groupChecked: any) => setColumnSelected(groupChecked);

  const handleVisibleDropdown = (visible: boolean) => setVisible(visible);

  const handleSelectColumns = () => {
    setVisible(false);
    getListColumn(columnSelected);
  };

  const menu = (
    <div className="setting-icon__menu">
      <Row className="row-label">
        <Col md={12}>
          <EllipsisText className="label" text={t('revenue_management.txt_column_setting')} />
        </Col>
        <Col md={12}>
          <EllipsisText
            className="total-selected"
            text={`${columnSelected.length} ${t('revenue_management.txt_columns_selected')}`}
          />
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <Checkbox.Group options={columnsItem} onChange={handleChecked} defaultValue={listColumn} />
        </Col>
      </Row>
      <AppButton
        disabled={isDisabledApply}
        className="app-button"
        text={t('revenue_management.txt_apply')}
        onClick={handleSelectColumns}
      />
    </div>
  );

  return (
    <Formik initialValues={initialParams} onSubmit={noop} innerRef={formRef}>
      {({ setFieldValue, values }) => (
        <Form className="revenue-management-search-form">
          <Row justify="space-between" gutter={20} className="revenue-management-search-form__row">
            <Col lg={8} sm={12} xs={24}>
              <FormItem
                name={KEYWORD}
                placeholder={t('revenue_management.txt_search_placeholder')}
                onSearch={handleFieldChange(setFieldValue, KEYWORD)}
                typeInput={TYPE_INPUT.SEARCH}
              />
            </Col>
            <Col lg={3} sm={12} xs={24} className="col-date">
              <FormItem
                name={FROM}
                className="col-date__date"
                placeholder={t('revenue_management.txt_start_date')}
                onChange={handleFieldChange(setFieldValue, FROM)}
                disabledDate={disabledFromDate(values?.[UNTIL])}
                typeInput={TYPE_INPUT.DATE}
              />
            </Col>
            <Col lg={3} sm={12} xs={24} className="col-date">
              <FormItem
                name={UNTIL}
                className="col-date__date"
                placeholder={t('revenue_management.txt_end_date')}
                onChange={handleFieldChange(setFieldValue, UNTIL)}
                disabledDate={disabledUntilDate(values?.[FROM])}
                typeInput={TYPE_INPUT.DATE}
              />
            </Col>
            <Col lg={2} sm={12} xs={24} className="col-select">
              <FormItem
                name={STANDARD}
                className="search-form__name--input"
                placeholder={t('revenue_management.txt_type')}
                onChange={handleFieldChange(setFieldValue, STANDARD)}
                typeInput={TYPE_INPUT.SELECT}
                options={optionSelect}
              />
            </Col>
            <Col lg={1} sm={12} xs={24} className="">
              <img src={RefreshIcon} onClick={handleResetForm} className="cursor-pointer" />
            </Col>
            <Col lg={6} sm={12} xs={24} className="setting-icon">
              <AppDropdown overlay={menu} visible={visible} onVisibleChange={handleVisibleDropdown}>
                <img src={SettingIcon} className="cursor-pointer" />
              </AppDropdown>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default SearchForm;
