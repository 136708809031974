import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import classNames from 'classnames';

import AppLogo from 'resources/images/login_logo.png';
import MenuIcon from 'resources/svg/menu_icon.svg';

import routes from 'constants/routes';

const { Sider: SiderAntd } = Layout;
const { Item } = Menu;

type SiderType = {
  selectedKey: string;
  onRedirectPage: (e: any) => void;
};

const Sider = ({ selectedKey, onRedirectPage }: SiderType) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = () => setCollapsed(!collapsed);

  return (
    <SiderAntd collapsible className="app-sider" width={215} collapsed={collapsed}>
      <div
        className={classNames('app-sider__top', {
          'justify-content-center': collapsed,
          'justyfy-content-between': !collapsed,
        })}
      >
        <Link
          to={routes.privateRoutes?.[0]?.path}
          className={classNames('top__link', {
            'd-none': collapsed,
          })}
        >
          <img src={AppLogo} className="top__logo" />
        </Link>
        <img src={MenuIcon} onClick={handleToggleCollapse} className="top__bar" />
      </div>
      <Menu className="app-sider__menu" mode="inline" selectedKeys={[selectedKey]}>
        {routes.privateRoutes.map(({ icon: Icon, name, isShow, path }: any, index: number) => (
          <Fragment key={index}>
            {isShow && (
              <Item className="app-sider__menu--item" key={path} icon={<Icon />} onClick={onRedirectPage}>
                {t(name)}
              </Item>
            )}
          </Fragment>
        ))}
      </Menu>
    </SiderAntd>
  );
};

export default Sider;
