import React from 'react';

import Login from 'pages/login';
import Home from 'pages/home';
import NFTCreation from 'pages/nft-creation';
import NFTManagement from 'pages/nft-management';
import NFTDetail from 'pages/nft-detail';
import NFTListForSale from 'pages/nft-list-for-sale';
import RevenueManagement from 'pages/revenue-management';

import PrivateLayout from 'components/Layout/Private';
import PublicLayout from 'components/Layout/Public';

import DashboardIcon from 'resources/svg/DashboardIcon';
import NFTIcon from 'resources/svg/NFTIcon';
import RevenueIcon from 'resources/svg/RevenueIcon';

import { PAGE_TAB_QUERY } from './nft';

export const routeURLs = {
  LOGIN: '/login',
  HOME: '/',
  NFT: '/nft',
  NFT_DETAIL: '/nft/detail',
  NFT_EDITION: '/nft/edit',
  NFT_CREATION: '/nft/create',
  NFT_LIST_FOR_SALE: '/nft/list-for-sale',
  DASHBOARD: '/dashboard',
  REVENUE: '/revenue',
};

export const renderRoutes = {
  NFT_DETAIL: (id: string | number, query?: string) => `${routeURLs.NFT_DETAIL}/${id}?${PAGE_TAB_QUERY}=${query}`,
  NFT_EDITION: (id: string | number) => `${routeURLs.NFT_EDITION}/${id}`,
  NFT_LIST_ON_SALE: (id: string | number) => `${routeURLs.NFT_LIST_FOR_SALE}/${id}`,
};

export const EXTERNAL_URL = {
  BSC_SCAN: 'https://testnet.bscscan.com/tx',
  BSC_SCAN_ADDRESS: 'https://testnet.bscscan.com/address',
  BSC_SCAN_TOKEN: 'https://testnet.bscscan.com/token',
};

export const externalRoutes = {
  MARKET_NFT_DETAIL: (id: string) => `${process.env.REACT_APP_PUBLIC_DOMAIN}/nft/${id}`,
  BSC_SCAN: (transaction: string) => `${EXTERNAL_URL.BSC_SCAN}/${transaction}`,
  BSC_SCAN_ADDRESS: (address: string) => `${EXTERNAL_URL.BSC_SCAN_ADDRESS}/${address}`,
  BSC_SCAN_TOKEN: (contractAddress: string, tokenId: string) =>
    `${EXTERNAL_URL.BSC_SCAN_TOKEN}/${contractAddress}?a=${tokenId}`,
};

const routes = {
  privateRoutes: [
    {
      name: 'menu.txt_home',
      path: routeURLs.HOME,
      component: Home,
      layout: PrivateLayout,
      exact: true,
    },
    {
      name: 'menu.txt_dashboard',
      path: routeURLs.HOME,
      component: Home,
      layout: PrivateLayout,
      exact: true,
      isShow: true,
      icon: DashboardIcon,
    },
    {
      name: 'menu.txt_nft',
      path: routeURLs.NFT,
      component: NFTManagement,
      layout: PrivateLayout,
      exact: true,
      isShow: true,
      icon: NFTIcon,
    },
    {
      name: 'menu.txt_nft',
      path: `${routeURLs.NFT_DETAIL}/:id`,
      component: NFTDetail,
      layout: PrivateLayout,
      exact: true,
    },
    {
      name: 'menu.txt_nft',
      path: `${routeURLs.NFT_EDITION}/:id`,
      component: NFTCreation,
      layout: PrivateLayout,
      exact: true,
    },
    {
      name: 'menu.txt_nft',
      path: routeURLs.NFT_CREATION,
      component: NFTCreation,
      layout: PrivateLayout,
      exact: true,
    },
    {
      name: 'menu.txt_nft',
      path: `${routeURLs.NFT_LIST_FOR_SALE}/:id`,
      component: NFTListForSale,
      layout: PrivateLayout,
      exact: true,
    },
    {
      name: 'menu.txt_revenue',
      path: routeURLs.REVENUE,
      component: RevenueManagement,
      layout: PrivateLayout,
      exact: true,
      isShow: true,
      icon: RevenueIcon,
    },
  ],
  publicRoutes: [
    {
      name: 'menu.txt_login',
      path: routeURLs.LOGIN,
      component: Login,
      exact: true,
      layout: PublicLayout,
    },
  ],
};

export default routes;
