import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import selectAuthentication from 'redux/authentication/selector';
import selectedAddress from 'redux/address/selector';

import './i18n/i18n';
import { useAppSelector } from 'hooks/useStore';
import routes, { routeURLs } from 'constants/routes';

import './App.css';

const App = () => {
  const { address } = useAppSelector(selectedAddress.getAddress);
  const { authenticationToken } = useAppSelector(selectAuthentication.getAuthenticationToken);

  //Fix bug wallet connect for react
  window.Buffer = window.Buffer || require('buffer').Buffer;

  const route = address && authenticationToken ? routes.privateRoutes : routes.publicRoutes;

  return (
    <Router>
      <Switch>
        {route.map((route) => {
          const Component = route?.component || Fragment;
          const Layout = route?.layout || Fragment;

          return (
            <Route
              path={route.path}
              key={route.path}
              exact={route.exact}
              render={(props: any) => <Layout>{<Component {...props} />}</Layout>}
            />
          );
        })}
        <Route
          path="*"
          exact
          render={(props: any) => (address ? <Redirect to={routeURLs.HOME} /> : <Redirect to={routeURLs.LOGIN} />)}
        />
      </Switch>
    </Router>
  );
};

export default withTranslation()(App);
