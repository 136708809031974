import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import PreviewNFT from 'components/PreviewNFT';
import EllipsisText from 'components/EllipsisText';
import NumberFormat from 'components/NumberFormat';
import { NftDetailContext } from 'pages/nft-list-for-sale';

import { useGetConfig } from 'hooks/useGetConfig';
import { getAttributeFieldNFTValues, getDefaultFieldNFTValues } from 'utils';
import { DOLLAR_TEXT, EMPTY_DEFAULT_TEXT } from 'constants/common';
import { NFT_DECIMAL_SCALE, NFT_DEFAULT_CREATE_FIELD, NFT_DEFAULT_PRICE } from 'constants/nft';

const { UNIT_PRICE, QUANTITY } = NFT_DEFAULT_CREATE_FIELD;

const Preview = () => {
  const { nftDetail = {} } = useContext(NftDetailContext) as any;
  const { values } = useFormikContext() as any;
  const { currency } = useGetConfig();
  const unitPrice = values?.[UNIT_PRICE];
  const unitCurrency = currency?.usd;

  const previewSrc = nftDetail?.image?.url;
  const attributeFieldValues = getAttributeFieldNFTValues(nftDetail) as object;
  const defaultFieldValues = getDefaultFieldNFTValues(nftDetail) as object;
  const nft = {
    ...defaultFieldValues,
    ...attributeFieldValues,
  };

  return (
    <div className="nft-profile-preview">
      <PreviewNFT previewSrc={previewSrc} nft={{ ...nft, [QUANTITY]: values?.[QUANTITY] }}>
        <div className="nft-profile-preview__sale-order">
          <div className="sale-order-quantity">
            <img src={currency?.icon} />
            <EllipsisText text={values?.unitPrice ?? EMPTY_DEFAULT_TEXT} />
            <span className="sale-order-quantity__symbol">{currency?.symbol}</span>
          </div>
          <div className="sale-order-price">
            (
            {unitPrice && unitCurrency ? (
              <>
                {DOLLAR_TEXT}
                <NumberFormat
                  thousandSeparator
                  value={unitPrice * unitCurrency}
                  displayType="text"
                  decimalScale={NFT_DECIMAL_SCALE}
                />
              </>
            ) : (
              NFT_DEFAULT_PRICE
            )}
            )
          </div>
        </div>
      </PreviewNFT>
    </div>
  );
};

export default Preview;
