import { useTranslation } from 'react-i18next';

import Table from 'components/Table';
import AppNumber from 'components/AppNumber';
import AppLoading from 'components/AppLoading';
import EllipsisText from 'components/EllipsisText';
import NumberFormat from 'components/NumberFormat';
import PageContent from 'components/PageContent';
import ResponsiveImage from 'components/ResponsiveImage';
import { useGetTransactionOverview } from 'pages/home/hooks';

import DetailIcon from 'resources/svg/detail_icon.svg';
import RedirectIcon from 'resources/svg/redirect_icon.svg';

import { useGetConfig } from 'hooks/useGetConfig';

import { getRowKey } from 'utils';

import { ZERO_VALUE } from 'constants/common';
import { externalRoutes, renderRoutes } from 'constants/routes';
import { NFT_USD_DECIMAL_SCALE } from 'constants/nft';

const RecentTransaction = () => {
  const { t } = useTranslation();

  const { currency } = useGetConfig();
  const { data, loading } = useGetTransactionOverview();

  const columns = [
    {
      title: t('dashboard.txt_no'),
      width: '5%',
      render: (_value: any, _row: any, index: number) => index + 1,
    },
    {
      title: t('dashboard.txt_nft_name'),
      width: '25%',
      dataIndex: 'nft.imgUrl',
      key: 'nft.imgUrl',
      ellipsis: true,
      render: (_value: string, row: any) => {
        return (
          <div className="view-nft-name">
            <ResponsiveImage src={row?.nft?.image?.smallUrl} />
            <EllipsisText text={row?.nft?.name} />
          </div>
        );
      },
    },
    {
      title: t('dashboard.txt_quantity'),
      width: '15%',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value: any) => {
        return <NumberFormat thousandSeparator value={value} displayType="text" />;
      },
    },
    {
      title: t('dashboard.txt_price'),
      width: '15%',
      dataIndex: 'saleOrder',
      key: 'saleOrder',
      render: (_value: any, row: any) => {
        return (
          <NumberFormat
            displayType="text"
            thousandSeparator
            value={row?.saleOrder?.unitPrice}
            suffix={currency?.symbol}
          />
        );
      },
    },
    {
      title: t('dashboard.txt_sub_total'),
      width: '15%',
      dataIndex: 'subTotal',
      key: 'subTotal',
      render: (value: any) => {
        return (
          <AppNumber value={value} decimalScale={NFT_USD_DECIMAL_SCALE} suffix={currency?.symbol} isNotFormatDecimal />
        );
      },
    },
    {
      title: '',
      width: '15%',
      dataIndex: 'hash',
      key: 'hash',
      render: (_value: any, row: any) => {
        return (
          <div className="view-nft-link">
            <a href={renderRoutes.NFT_DETAIL(row?.nft?.id)} target="_blank" rel="noreferrer">
              <img src={DetailIcon} className="cursor-pointer" />
            </a>
            <a href={externalRoutes.MARKET_NFT_DETAIL(row?.nft?.id)} target="_blank" rel="noreferrer">
              <img src={RedirectIcon} className="cursor-pointer" />
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <PageContent className="h-full">
      <div className="dashboad-recent-transaction">
        <div className="title">{t('dashboard.txt_recent_transactions')}</div>
      </div>
      <AppLoading loading={loading}>
        <Table
          columns={columns}
          total={ZERO_VALUE}
          dataSource={data}
          scroll={{ x: 606 }}
          rowKey={getRowKey}
          className="dashboad-recent-transaction__table"
        />
      </AppLoading>
    </PageContent>
  );
};

export default RecentTransaction;
