import React from 'react';
import NumberFormat from '../NumberFormat';

import { formatCurrency, removeTrailingZeros } from 'utils';

import { NFT_DECIMAL_SCALE } from 'constants/nft';

type AppNumberProps = {
  value: number | any;
  decimalScale?: number;
  className?: string;
  suffix?: string;
  isNotFormatDecimal?: boolean;
};

const AppNumber = ({
  value,
  className,
  decimalScale = NFT_DECIMAL_SCALE,
  isNotFormatDecimal,
  ...props
}: AppNumberProps) => {
  return (
    <NumberFormat
      thousandSeparator
      value={removeTrailingZeros(formatCurrency(value, { isNotFormatDecimal }))}
      displayType="text"
      decimalScale={decimalScale}
      {...props}
    />
  );
};

export default AppNumber;
