import React from 'react';
import { TFunction } from 'react-i18next';

import AppAddress from 'components/AppAddress';
import NumberFormat from 'components/NumberFormat';
import EllipsisText from 'components/EllipsisText';
import ViewDetailButton from 'pages/revenue-management/components/ViewDetailButton';

import { formatDate } from 'utils';
import { NFT_DECIMAL_SCALE_PRICE, NFT_MARKET_CHANNEL, NFT_USD_DECIMAL_SCALE } from 'constants/nft';
import { EMPTY_DEFAULT_TEXT } from 'constants/common';
import AppNumber from 'components/AppNumber';

export const columns = (t: TFunction, page: number, limit: number) => [
  {
    title: t('nft_detail.txt_no'),
    width: 100,
    render: (_value: any, _row: any, index: number) => (page - 1) * limit + index + 1,
  },
  {
    title: t('nft_detail.txt_sale_date'),
    width: 200,
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    render: (value: any) => formatDate(value),
  },
  {
    title: t('nft_detail.txt_market_channel'),
    width: 150,
    dataIndex: 'saleOrder',
    key: 'saleOrder',
    render: (value: any) => {
      const channel = NFT_MARKET_CHANNEL.find((item) => item.value === value?.type) as any;
      return <EllipsisText text={t(channel.name)} />;
    },
  },
  {
    title: t('nft_detail.txt_quantity'),
    width: 100,
    dataIndex: 'quantity',
    key: 'quantity',
    sorter: true,
    render: (value: any) => {
      return <NumberFormat thousandSeparator value={value} displayType="text" />;
    },
  },
  {
    title: t('nft_detail.txt_price'),
    width: 100,
    dataIndex: 'saleOrder.unitPrice',
    key: 'saleOrder.unitPrice',
    sorter: true,
    render: (_value: any, row: any) => {
      return <NumberFormat thousandSeparator value={row?.saleOrder?.unitPrice} displayType="text" />;
    },
  },

  {
    title: t('nft_detail.txt_subtotal'),
    width: 100,
    dataIndex: 'saleOrder',
    key: 'saleOrder',
    sorter: true,
    render: (value: any, row: any) => {
      return <AppNumber value={value?.unitPrice * row?.quantity} decimalScale={NFT_USD_DECIMAL_SCALE} />;
    },
  },

  {
    title: t('nft_detail.txt_royalties'),
    width: 100,
    dataIndex: 'revenue',
    key: 'revenue',
    sorter: true,
    render: (_value: any, row: any) => {
      return row?.saleOrder?.type === NFT_MARKET_CHANNEL[2].value ? (
        <AppNumber value={row?.revenue} decimalScale={NFT_USD_DECIMAL_SCALE} />
      ) : (
        EMPTY_DEFAULT_TEXT
      );
    },
  },
  {
    title: t('nft_detail.txt_actions'),
    dataIndex: '_id',
    key: 'actions',
    width: 100,
    render: (value: any) => <ViewDetailButton revenueId={value} />,
  },
];
