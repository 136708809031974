import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Col, Row } from 'antd';
import get from 'lodash/get';
import trim from 'lodash/trim';

import AppLoading from 'components/AppLoading';
import PageHeader from 'components/PageHeader';
import NFTContent from './components/NFTContent';
import NFTAtrribute from './components/NFTAtrribute';
import NFTPreview from './components/NFTPreview';
import NFTListForSale from './components/NFTListForSale';
import NFTGroupButton from './components/NFTGroupButton';
import { TYPE_INPUT } from 'components/FormItem';
import ModalUnsavedChange from 'components/Modal/ModalUnsaveChange';

import selectedConfig from 'redux/config/selector';
import { useCreateOrEditNFT, useGetDetailNFT } from './hooks';
import { useAppSelector } from 'hooks/useStore';
import { useWarnModalPage } from 'hooks/useWarnModalForPage';

import {
  checkValueNftChange,
  clearRequestParams,
  getAttributeFieldNFTValues,
  getDefaultFieldNFTValues,
  getFormatedNFT,
} from 'utils';
import { getNftSchema } from 'utils/schema';
import { TOKEN_SUPPORT } from 'constants/common';
import { NFT_DEFAULT_CREATE_FIELD, NFT_MEDIA, SERVER_PARAMS_CONFIG } from 'constants/nft';
import { renderRoutes, routeURLs } from 'constants/routes';

const {
  NAME,
  ROYALTYFEE,
  IS_PUT_ON_SALE,
  TOTAL_SUPPLY,
  QUANTITY,
  CURRENCY,
  UNIT_PRICE,
  DESCRIPTION,
  IMAGE_MEDIUM,
  IMAGE_SMALL,
  FILE,
  FILE_PREVIEW,
} = NFT_DEFAULT_CREATE_FIELD;
const { TOKEN, SALE_ORDER, ATTRIBUTES } = SERVER_PARAMS_CONFIG;

const initFormValue = {
  [NAME]: '',
  [FILE]: {
    fileList: [],
    previewContent: '',
  },
  [FILE_PREVIEW]: {
    fileList: [],
    previewContent: '',
  },
  [IMAGE_MEDIUM]: '',
  [IMAGE_SMALL]: '',
  [ROYALTYFEE]: '',
  [TOTAL_SUPPLY]: '',
  [DESCRIPTION]: '',
  [IS_PUT_ON_SALE]: false,
  [UNIT_PRICE]: '',
  [QUANTITY]: '',
  [CURRENCY]: TOKEN_SUPPORT.value,
} as any;

const NFTCreation = () => {
  const { t } = useTranslation();
  const formikRef = useRef(null) as any;
  const { id } = useParams() as string | any;

  const { loading: loadingCreateNFT, onCreateNFT, onEditNFT } = useCreateOrEditNFT();

  const { general = {} } = useAppSelector(selectedConfig.getConfig);
  const { attributes = [] } = general;

  const { nftDetail, loading: loadingDetailNFT } = useGetDetailNFT(id) as any;

  const backUrl = id ? renderRoutes.NFT_DETAIL(id) : routeURLs.NFT;

  const { visibleModalUnsaved, setValueChange, onCloseModalUnsaved, afterCloseModalUnsaved, onBackClick, onDiscard } =
    useWarnModalPage(backUrl);

  useEffect(() => {
    const defaultAttributesValues = attributes.reduce((acc: any, attribute: any) => {
      const typeInput = attribute?.type?.toUpperCase();
      const fieldValue = typeInput === TYPE_INPUT.SELECT ? null : '';

      acc[`${attribute?.name}`] = fieldValue;
      initFormValue[`${attribute?.name}`] = fieldValue;
      return acc;
    }, {});
    formikRef?.current?.setValues({
      ...formikRef?.current?.values,
      ...defaultAttributesValues,
    });
  }, [attributes, formikRef]);

  useEffect(() => {
    if (nftDetail?._id) {
      const attributeFieldValues = getAttributeFieldNFTValues(nftDetail) as object;
      const defaultFieldValues = getDefaultFieldNFTValues(nftDetail) as object;

      formikRef.current.setValues({
        ...defaultFieldValues,
        ...attributeFieldValues,
      });
    }
  }, [nftDetail, attributes]);

  const getOriginFile = (file: any) => get(file, ['fileList', 0, 'originFileObj']);

  const handleSubmit = async (values: any = {}) => {
    const { file, filePreview, imageMedium, imageSmall } = values;

    let image = getOriginFile(file);
    const mediaType = getFormatedNFT(values?.file);
    if (mediaType !== NFT_MEDIA.IMAGE) {
      image = getOriginFile(filePreview);
    }

    const attributesData = attributes.reduce((acc: any, attribute: any) => {
      acc[`${ATTRIBUTES}[${attribute?.name}]`] = values?.[attribute?.name];
      return acc;
    }, {});

    const data = {
      [NAME]: trim(values?.[NAME]),
      mediaFile: mediaType !== NFT_MEDIA.IMAGE ? getOriginFile(file) : undefined,
      image,
      mediaType: mediaType !== NFT_MEDIA.IMAGE ? mediaType : undefined,
      [IMAGE_MEDIUM]: image && imageMedium,
      [IMAGE_SMALL]: image && imageSmall,
      [DESCRIPTION]: trim(values?.[DESCRIPTION]),
      [ROYALTYFEE]: values?.[ROYALTYFEE],
      [IS_PUT_ON_SALE]: values?.[IS_PUT_ON_SALE]?.toString(),
      [`${TOKEN}[${TOTAL_SUPPLY}]`]: values?.[TOTAL_SUPPLY],
      [`${SALE_ORDER}[${QUANTITY}]`]: values?.[QUANTITY],
      [`${SALE_ORDER}[${CURRENCY}]`]: values?.[CURRENCY],
      [`${SALE_ORDER}[${UNIT_PRICE}]`]: values?.[UNIT_PRICE],
      ...attributesData,
    } as any;

    const formatData = clearRequestParams(data);

    const formData = new FormData();
    for (const key in formatData) {
      formData.append(key, formatData[key]);
    }
    if (id) {
      onEditNFT(formData, values?.[IS_PUT_ON_SALE], id);
    } else {
      onCreateNFT(formData, values?.[IS_PUT_ON_SALE]);
    }
  };

  const nftSchema = getNftSchema(t);

  return (
    <AppLoading loading={loadingDetailNFT || loadingCreateNFT}>
      <div className="nft-creation-page">
        <PageHeader
          showBack
          title={!id ? t('nft_creation.txt_nft_creation') : t('nft_creation.txt_edit_nft')}
          onBack={onBackClick}
        />

        <Formik innerRef={formikRef} initialValues={initFormValue} onSubmit={handleSubmit} validationSchema={nftSchema}>
          {({ values }: any) => {
            setValueChange(checkValueNftChange(id ? nftDetail : initFormValue, values, !!id));
            return (
              <Form className="nft-creation-page-form">
                <Row gutter={20} justify="space-between">
                  <Col lg={16} xs={24}>
                    <NFTContent />
                    <NFTAtrribute />
                    <NFTListForSale />
                    <NFTGroupButton isSubmit={loadingCreateNFT} onDiscard={onDiscard} id={id} />
                  </Col>
                  <Col lg={8} xs={24}>
                    <NFTPreview />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>

        <ModalUnsavedChange
          visible={visibleModalUnsaved}
          onClose={onCloseModalUnsaved}
          backUrl={backUrl}
          afterClose={afterCloseModalUnsaved}
        />
      </div>
    </AppLoading>
  );
};

export default NFTCreation;
