import { useEffect } from 'react';

import { handleSetAppConfig } from 'redux/config/slice';
import { checkSusscessRequest } from 'services/api';

import configServices from 'services/config';
import { useAppDispatch } from './useStore';

export function useGetAppConfig() {
  const dispatch = useAppDispatch();

  const handleGetConfig = async () => {
    try {
      const response = await configServices.handleGetConfig();
      if (checkSusscessRequest(response)) {
        dispatch(handleSetAppConfig(response?.data));
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetConfig();
  }, []);
}
